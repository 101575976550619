import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      talent_member_id: string
    }

    resBody: {
      pdf_url: string
    }
  }
}>

export default mockMethods<Methods>({
  post: ({ reqBody }) => {
    const id =
      reqBody.talent_member_id === '11' || reqBody.talent_member_id === '10'
        ? reqBody.talent_member_id
        : '00'
    // 以下はサンプルPDFを返却
    //   talent_member_id 11: 高橋 理沙
    //   talent_member_id 10: 宮崎 裕美
    // それ以外は汎用PDF(ta_sample_00.pdf)を返却
    return {
      status: 200,
      resBody: {
        pdf_url: `/pdf/ta_sample_${id}.pdf`,
      },
    }
  },
})
