import type { Ref } from 'vue'

type Return = {
  isScrolled: Ref<boolean>
}

export function useScrollEffect(): Return {
  const isScrolled = ref(false)

  const onScroll: () => void = () => {
    const distance = 50
    isScrolled.value = window.scrollY > distance
  }

  onMounted(() => {
    window.addEventListener('scroll', onScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', onScroll)
  })

  return {
    isScrolled,
  }
}
