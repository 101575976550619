{
  "errors": {
    "default": "エラーが発生しました",
    "timeline": {
      "noChanges": "状態を変更するかメモを入力してください"
    }
  },
  "validations": {
    "required": "必須項目です",
    "numeric": "半角数字で入力してください",
    "email": "不正なメールアドレスです",
    "max": "{_field_}は{length}文字以下で入力してください",
    "max_value": "{max}以下で入力してください",
    "length": "{_field_}は{length}桁で入力してください",
    "being_the_past": "過去の年月日を入力してください",
    "before_delivery_date": "入社日はアンケート配信日よりも前に設定してください",
    "resigned_date": "退職日は必須です",
    "ipv4": "IPアドレスの形式が正しくありません",
    "within_a_year": "ダウンロードできる期間は最大で12ヶ月です",
    "correct_date_period": "日付の範囲指定が正しくありません",
    "within_a_year_delivery_date": "初回配信日は1年以内の日付を入力してください",
    "not_use_department_name": "{_field_}に「>」は使用できません"
  },
  "flashMessages": {
    "talentImport": {
      "integrationOn": "Talent Analyticsを連携しました",
      "integrationOff": "Talent Analyticsの連携を解除しました",
      "integrationOnFail": "Talent Analyticsの連携に失敗しました",
      "integrationOffFail": "Talent Analyticsの連携解除に失敗しました",
      "updateSuccess": "Talent Analyticsの受検結果を更新しました",
      "updateFail": "Talent Analytics受検結果の更新に失敗しました",
      "csvImportSuccess": "Talent Analytics受検結果の取り込みが成功しました",
      "csvImportFail": "Talent Analytics受検結果の取り込みが失敗しました"
    }
  },
  "uri": {
    "help": "https://form.run/{'@'}hronboard-support01",
    "contact": "https://lp.on-board.io/hronboard_contact.html",
    "mailHelp": "https://onboard.zendesk.com/hc/ja/articles/6705252843545",
    "slackHelp": "https://onboard.zendesk.com/hc/ja/articles/360000332822-slack%E9%80%A3%E6%90%BA",
    "smsHelp": "https://onboard.zendesk.com/hc/ja/articles/360000373662-SMS%E9%80%A3%E6%90%BA",
    "lineHelp": "https://onboard.zendesk.com/hc/ja/articles/900000039426",
    "settingImportHelp": "https://onboard.zendesk.com/hc/ja/articles/360019791113-%E3%82%A8%E3%83%B3%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%A7%E5%85%A5%E7%A4%BE%E3%81%95%E3%82%8C%E3%81%9F%E6%96%B9%E3%82%92HR-OnBoard%E3%81%A71%E5%B9%B4%E9%96%93%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BC%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%A8%E8%81%9E%E3%81%84%E3%81%A6%E3%81%84%E3%81%BE%E3%81%99-%E3%81%A9%E3%81%AE%E3%82%88%E3%81%86%E3%81%AB%E5%88%A9%E7%94%A8%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%AE%E3%81%A7%E3%81%97%E3%82%87%E3%81%86%E3%81%8B-",
    "memberImportHelp": "https://onboard.zendesk.com/hc/ja/articles/360019615654-%E3%82%A8%E3%83%B3%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%A8%E3%81%AE%E9%80%A3%E6%90%BA%E3%81%8C%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%97%E3%81%9F-%E5%85%A5%E7%A4%BE%E8%80%85%E3%81%AE%E7%99%BB%E9%8C%B2%E3%82%84%E7%B7%A8%E9%9B%86%E3%81%AF%E3%81%A9%E3%81%AE%E3%82%88%E3%81%86%E3%81%AB%E3%81%97%E3%81%9F%E3%82%89%E3%81%84%E3%81%84%E3%81%A7%E3%81%97%E3%82%87%E3%81%86%E3%81%8B-",
    "anniversaryHelp": "https://onboard.zendesk.com/hc/ja/articles/360000312481--%E5%85%A5%E7%A4%BE1%E5%B9%B4%E5%BE%8C%E6%B4%BB%E8%BA%8D%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88-%E3%81%A8%E3%81%AF",
    "makeEngage": "https://en-gage.net/company/make/account/",
    "loginEngage": "https://en-gage.net/company/logout/",
    "loginOnboard": "https://on-board.io/auth/login/",
    "hrobHelp": "https://onboard.zendesk.com/hc/ja",
    "corp": "https://corp.en-japan.com/",
    "corpStory": "https://corp.en-japan.com/services/story_hronboard.html",
    "addAccount": "https://onboard.zendesk.com/hc/ja/articles/360005872633",
    "accountHelp": "https://onboard.zendesk.com/hc/ja/articles/360000336761",
    "emotionHelp": "https://onboard.zendesk.com/hc/ja/articles/27701040916377"
  },
  "searchCategoryOptions": [
    {
      "val": "all",
      "label": "すべて"
    },
    {
      "val": "dashboard",
      "label": "ダッシュボード"
    },
    {
      "val": "member",
      "label": "メンバー"
    },
    {
      "val": "senior",
      "label": "上司"
    },
    {
      "val": "report",
      "label": "回答レポート"
    },
    {
      "val": "setting",
      "label": "設定"
    },
    {
      "val": "notification",
      "label": "お知らせ"
    }
  ],
  "member": {
    "interviewStatus": {
      "0": "対応なし",
      "1": "未対応",
      "2": "対応中",
      "3": "対応完了"
    },
    "importance": {
      "1": "高",
      "2": "中",
      "3": "低"
    },
    "conditions": {
      "1": "問題なし",
      "2": "やや注意",
      "3": "要フォロー"
    },
    "impression": {
      "1": "問題なし",
      "2": "順調",
      "3": "普通",
      "4": "やや不安...",
      "5": "不安...!"
    },
    "employingType": {
      "1": "正社員",
      "2": "契約社員",
      "3": "アルバイト・パート",
      "4": "その他"
    },
    "workingStatus": {
      "1": "在職中",
      "2": "休職中",
      "3": "退職",
      "4": "入社前辞退"
    },
    "initialEvaluatedPointGrades": {
      "1": "S",
      "2": "A",
      "3": "B",
      "4": "C"
    },
    "gender": {
      "1": "男性",
      "2": "女性",
      "3": "選択しない"
    },
    "isNewbie": {
      "1": "新卒採用",
      "0": "中途採用"
    },
    "resignReason": {
      "1": "仕事のスキル不足",
      "2": "職場環境とのミスマッチ",
      "3": "給与や待遇への不満",
      "4": "プライベート上の理由",
      "5": "健康上の理由",
      "6": "会社都合退職",
      "7": "その他"
    },
    "routeEmployed": {
      "1": "求人広告",
      "2": "人材紹介会社",
      "3": "ダイレクトリクルーティング",
      "4": "リファラル採用（社員紹介）",
      "5": "タレントプール",
      "6": "自社HP",
      "7": "再雇用",
      "8": "その他"
    },
    "graduationType": {
      "4": "大学院卒",
      "1": "大学卒",
      "2": "高校卒",
      "5": "高専卒",
      "6": "短大卒",
      "7": "専門各種学校卒",
      "8": "その他",
      "3": "中卒"
    },
    "mailLanguage": ["日本語", "英語", "繁体字", "簡体字"],
    "appStatus": {
      "0": "未認証",
      "1": "認証待ち",
      "2": "認証済み"
    }
  },
  "anniversary": {
    "enqGroup": {
      "0": "活躍実感",
      "1": "イメージと現実のマッチング",
      "2": "上司サポート",
      "3": "組織・職場",
      "4": "ストレス要因",
      "5": "就業継続意欲",
      "6": "活躍評価（上司）"
    }
  },
  "lineAuth": {
    "status": {
      "0": "LINE未連携",
      "1": "LINE認証中",
      "2": "LINE認証失敗",
      "3": "LINE認証済"
    }
  },
  "smsAuth": {
    "status": {
      "0": "SMS未連携",
      "1": "SMS認証中",
      "2": "SMS認証失敗",
      "3": "SMS認証済"
    }
  },
  "tempMember": {
    "joined_site": {
      "1": "エン転職",
      "3": "AMBI",
      "4": "ミドルの転職"
    }
  },
  "companySecuritySetting": {
    "passwordComplexity": {
      "1": "半角英数字のみ",
      "2": "半角英数字と特殊文字の組み合わせ"
    },
    "passwordValidDays": {
      "0": "期限なし",
      "30": "30日",
      "60": "60日",
      "90": "90日",
      "120": "120日"
    },
    "allowPastPasswordCount": {
      "0": "制限なし",
      "1": "最近使ったパスワード1個まで使用不可",
      "2": "最近使ったパスワード2個まで使用不可",
      "3": "最近使ったパスワード3個まで使用不可",
      "4": "最近使ったパスワード4個まで使用不可",
      "5": "最近使ったパスワード5個まで使用不可"
    }
  },
  "annivAnswer": {
    "categories": [
      {
        "title": "就業継続意欲",
        "description": "これから先も今の会社で働き続ける意欲を示しています。",
        "commentKey": "motivation",
        "items": [
          {
            "name": "就業継続意欲",
            "questions": [
              {
                "index": 32,
                "question": "現在の会社で今後も働き続けたい"
              },
              {
                "index": 33,
                "question": "現在の会社で将来のキャリアイメージを持っている"
              }
            ]
          }
        ]
      },
      {
        "title": "活躍状況",
        "description": "現在の活躍状況を示しています。",
        "subCategories": [
          {
            "title": "活躍実感",
            "description": "本人の活躍状況について本人の自己評価を示しています。",
            "commentKey": "success",
            "items": [
              {
                "name": "貢献実感",
                "questions": [
                  {
                    "index": 0,
                    "question": "職場に貢献できている実感がある"
                  },
                  {
                    "index": 1,
                    "question": "仕事で周りから感謝されることがよくある"
                  }
                ]
              },
              {
                "name": "仕事フィット",
                "questions": [
                  {
                    "index": 2,
                    "question": "自分の能力やスキルを活かせる仕事だ"
                  },
                  {
                    "index": 3,
                    "question": "個性や自分らしさが発揮できる仕事だ"
                  }
                ]
              },
              {
                "name": "職場フィット",
                "questions": [
                  {
                    "index": 4,
                    "question": "職場の雰囲気は自分に合っている"
                  },
                  {
                    "index": 5,
                    "question": "職場の同僚との関係性は良好だ"
                  }
                ]
              },
              {
                "name": "被信頼感",
                "questions": [
                  {
                    "index": 6,
                    "question": "上司から期待されていると感じる"
                  },
                  {
                    "index": 7,
                    "question": "上司から必要とされていると感じる"
                  }
                ]
              }
            ]
          },
          {
            "title": "活躍評価",
            "description": "本人の活躍状況について上司による評価を示しています。",
            "commentKey": "senior",
            "items": [
              {
                "name": "高パフォーマンス(貢献度)",
                "questions": [
                  {
                    "index": 0,
                    "question": "{name}さんは仕事で高いパフォーマンスを発揮している"
                  },
                  {
                    "index": 1,
                    "question": "{name}さんは職場や他の社員に好影響を与えている"
                  }
                ]
              },
              {
                "name": "仕事フィット",
                "questions": [
                  {
                    "index": 2,
                    "question": "{name}さんは自分の能力やスキルを仕事で活かしている"
                  },
                  {
                    "index": 3,
                    "question": "{name}さんは仕事で個性や自分らしさを発揮している"
                  }
                ]
              },
              {
                "name": "職場フィット",
                "questions": [
                  {
                    "index": 4,
                    "question": "職場の雰囲気は{name}さんに合っている"
                  },
                  {
                    "index": 5,
                    "question": "{name}さんと職場の同僚との関係性は良好だ"
                  }
                ]
              },
              {
                "name": "信頼感",
                "questions": [
                  {
                    "index": 6,
                    "question": "私は{name}さんには大きな期待を持っている"
                  },
                  {
                    "index": 7,
                    "question": "私は{name}さんのことをとても必要としている"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "要因系指標",
        "description": "活躍状況の要因となり得る指標を示しています。",
        "subCategories": [
          {
            "title": "イメージと現実のマッチング",
            "description": "入社前のイメージと現実がどのくらい合っていたかを示しています。",
            "commentKey": "matching",
            "items": [
              {
                "name": "仕事内容",
                "questions": [
                  {
                    "index": 8,
                    "question": "現在の仕事内容は入社前のイメージに近い"
                  },
                  {
                    "index": 9,
                    "question": "仕事の進め方（段取りや手続きなど）は入社前のイメージに近い"
                  }
                ]
              },
              {
                "name": "同僚",
                "questions": [
                  {
                    "index": 10,
                    "question": "社員の雰囲気は入社前のイメージに近い"
                  },
                  {
                    "index": 11,
                    "question": "社員の業務知識やスキルレベルは入社前のイメージに近い"
                  }
                ]
              },
              {
                "name": "給与・待遇",
                "questions": [
                  {
                    "index": 12,
                    "question": "賞与額は入社前の期待を満たしている"
                  },
                  {
                    "index": 13,
                    "question": "昇給の金額・タイミングは入社前の期待を満たしている"
                  }
                ]
              }
            ]
          },
          {
            "title": "上司サポート",
            "description": "上司からの支援をどれくらい受けているかを示しています。",
            "commentKey": "support",
            "items": [
              {
                "name": "役割・目的明確化",
                "questions": [
                  {
                    "index": 14,
                    "question": "上司は職場での自分の役割を具体的に提示してくれる"
                  },
                  {
                    "index": 15,
                    "question": "上司は自分の仕事の目標を明確に提示してくれる"
                  }
                ]
              },
              {
                "name": "1on1",
                "questions": [
                  {
                    "index": 16,
                    "question": "上司は1対1で話す時間を定期的に作ってくれている"
                  },
                  {
                    "index": 17,
                    "question": "上司は自分の話を真剣に聞いてくれている"
                  }
                ]
              },
              {
                "name": "成長フィードバック",
                "questions": [
                  {
                    "index": 18,
                    "question": "上司は私の仕事について、学習や成長を促すことを意識してフィードバックしてくれる"
                  },
                  {
                    "index": 19,
                    "question": "上司は私の仕事ぶりがより改善するための有益な情報を与えてくれる"
                  }
                ]
              }
            ]
          },
          {
            "title": "組織・職場",
            "description": "職場の風土やコミュニケーションが良好かどうかを示しています。",
            "commentKey": "workplace",
            "items": [
              {
                "name": "入社者歓迎風土",
                "questions": [
                  {
                    "index": 20,
                    "question": "入社時に自分を歓迎してくれるイベントがあった"
                  },
                  {
                    "index": 21,
                    "question": "新しく入社する社員を歓迎する風土がある"
                  }
                ]
              },
              {
                "name": "同僚サポート",
                "questions": [
                  {
                    "index": 22,
                    "question": "職場の同僚は、仕事で困った時に有益なアドバイスをしてくれる"
                  },
                  {
                    "index": 23,
                    "question": "職場の同僚は、あなたが精神的につらい時、支えになってくれる"
                  }
                ]
              },
              {
                "name": "コミュニケーション風土",
                "questions": [
                  {
                    "index": 24,
                    "question": "他のメンバーに対して躊躇なく自分の意見を発言できる職場だ"
                  },
                  {
                    "index": 25,
                    "question": "職場では活発なコミュニケーションが行き交っている"
                  }
                ]
              }
            ]
          },
          {
            "title": "ストレス要因",
            "description": "数値が低いほどストレスが少なく良好な状態を示します。",
            "note": "※数値が低いほど良い結果となります。",
            "reversedScore": true,
            "commentKey": "stress",
            "items": [
              {
                "name": "業務過多",
                "questions": [
                  {
                    "index": 26,
                    "question": "残業が多い"
                  },
                  {
                    "index": 27,
                    "question": "常に多くの量をこなす必要のある仕事だ"
                  }
                ]
              },
              {
                "name": "業務過少",
                "questions": [
                  {
                    "index": 28,
                    "question": "業務時間中に手が空くことがよくある"
                  },
                  {
                    "index": 29,
                    "question": "自分の能力と比べて簡単すぎる仕事だ"
                  }
                ]
              },
              {
                "name": "精神的プレッシャー",
                "questions": [
                  {
                    "index": 30,
                    "question": "仕事の目標達成へのプレッシャーが常にある"
                  },
                  {
                    "index": 31,
                    "question": "ミスが許されないというプレッシャーのかかる仕事だ"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "annivAnswerOld": {
    "question": [
      "職場に貢献できている実感がある",
      "自分の能力やスキルを活かせる仕事だ",
      "職場の雰囲気は自分に合っている",
      "上司から期待されていると感じる",
      "仕事で周りから感謝されることがよくある",
      "個性や自分らしさが発揮できる仕事だ",
      "職場の同僚との関係性は良好だ",
      "上司から必要とされていると感じる",
      "自分の仕事に関する自己学習を継続的に行っている",
      "社内での人間関係を広げようと積極的に動いている",
      "自分自身が成長するために、上司にアドバイスやフィードバックを頻繁に求めている",
      "業務とは直接関係のない分野も自己啓発を行っている",
      "自らの社内ネットワークを通じて様々な情報を得ている",
      "仕事の成果を高めるために、上司や同僚に積極的にアドバイスやフィードバックを求めている",
      "現在の業務に必要な知識・スキルを入社前から持っていた",
      "選考中に面接官に対して自分の弱みや課題を率直に伝えていた",
      "入社直後は、職場の暗黙のルールを理解しようと努力していた",
      "選考中に面接官から、自社のネガティブな面についても率直に説明された",
      "現在の業務に必要な経験を入社前にしていた",
      "入社直後に、自分の弱みや課題と真摯に向き合い改善した",
      "入社直後は、職場に上手く馴染めるように自ら積極的に行動していた",
      "選考中に会った社員は、自社の実態についてありのままの情報を提供してくれた",
      "現在の仕事内容は入社前のイメージに近い",
      "仕事の進め方（段取りや手続きなど）は入社前のイメージに近い",
      "社員の雰囲気は入社前のイメージに近い",
      "賞与額は入社前の期待を満たしている",
      "仕事の難易度は入社前の想定通りだ",
      "仕事上で任される裁量の幅は入社前のイメージに近い",
      "社員の業務知識やスキルレベルは入社前のイメージに近い",
      "昇給の金額・タイミングは入社前の期待を満たしている",
      "入社時に自分を歓迎してくれるイベントがあった",
      "他のメンバーに対して躊躇なく自分の意見を発言できる職場だ",
      "この1年間、OJTを受ける機会が十分にあった",
      "職場の同僚は、仕事で困った時に有益なアドバイスをしてくれる",
      "新しく入社する社員を歓迎する風土がある",
      "職場では活発なコミュニケーションが行き交っている",
      "この1年間、業務に関する研修機会が十分にあった",
      "職場の同僚は、あなたが精神的につらい時、支えになってくれる",
      "残業が多い",
      "業務時間中に手が空くことがよくある",
      "仕事の目標達成へのプレッシャーが常にある",
      "自分の仕事がどんな役割を持つのかを理解できている",
      "現在の仕事は進め方を自分で計画することができる",
      "現在の仕事では、簡単には解決できない課題に直面する",
      "常に多くの量をこなす必要のある仕事だ",
      "自分の能力と比べて簡単すぎる仕事だ",
      "ミスが許されないというプレッシャーのかかる仕事だ",
      "現在の仕事は、社会にとって有意義な仕事である",
      "現在の仕事には十分な自己裁量がある",
      "現在の仕事で直面する課題を解決するためには、これまでにない工夫やアイデアが必要だ",
      "上司は職場での自分の役割を具体的に提示してくれる",
      "上司は1対1で話す時間を定期的に作ってくれている",
      "上司は私の仕事について、学習や成長を促すことを意識してフィードバックしてくれる",
      "上司は仕事の問題解決のために社内の誰に聞けば良いか教えてくれる",
      "上司は、部下の育成にやりがいを持って取り組んでくれているように感じる",
      "上司は的確なアドバイスをしてくれる",
      "上司は自分の仕事の目標を明確に提示してくれる",
      "上司は自分の話を真剣に聞いてくれている",
      "上司は私の仕事ぶりがより改善するための有益な情報を与えてくれる",
      "上司は私の仕事がうまく回るように社内外の人たちに協力依頼をしてくれる",
      "上司は、自分の重要な仕事のひとつに部下育成があるということを理解していると思う",
      "上司は質問や相談に対し、分かりやすく説明してくれる"
    ],
    "seniorQuestion": [
      "{name}さんは職場に貢献している",
      "{name}さんは仕事で高いパフォーマンスを発揮している",
      "私は{name}さんには大きな期待を持っている",
      "{name}さんは仕事で周りから感謝されることがよくある",
      "{name}さんは他の社員に好影響を与えている",
      "私は{name}さんのことをとても必要としている",
      "{name}さんに仕事の目標を明確に提示している",
      "{name}さんと1対1で話す時間を定期的に作っている",
      "{name}さんの学習や成長を促すことを意識して本人に仕事のフィードバックを行っている",
      "{name}さんが直面した問題に応じて、その問題を解決するために社内の誰に聞けば良いかを伝えている",
      "{name}さんが初めて取り組む仕事でも上手く対応できるよう支援している",
      "{name}さんに職場での役割を具体的に提示している",
      "{name}さんの正直な気持ちを聞く機会を定期的に設けている",
      "{name}さんの長期的なキャリアにプラスとなる有益なアドバイスを伝えている",
      "{name}さんの仕事がうまく回るように関係者に協力依頼をしている",
      "{name}さんの業務習得状況にあわせた仕事のサポートを行っている"
    ],
    "answer": [
      "該当なし",
      "当てはまらない",
      "やや当てはまらない",
      "どちらでもない",
      "やや当てはまる",
      "当てはまる"
    ]
  },
  "routes": {
    "/company/dashboard/index": {
      "uri": "/company/dashboard/index",
      "task": "ダッシュボード表示"
    },
    "/company/member/anniversary": {
      "uri": "/company/member/anniversary",
      "task": "入社後1年後活躍アンケート"
    },
    "/company/member/anniversary/pdf": {
      "uri": "/company/member/anniversary/pdf",
      "task": "入社後1年後活躍アンケート：PDFダウンロード"
    },
    "/company/member/csv": {
      "uri": "/company/member/csv",
      "task": "メンバー一括登録メニュー"
    },
    "/company/member/csv_update": {
      "uri": "/company/member/csv_update",
      "task": "メンバー一括更新メニュー"
    },
    "/company/member/form/": {
      "uri": "/company/member/form/",
      "task": "メンバーフォーム"
    },
    "/company/member/import": {
      "uri": "/company/member/import",
      "task": "メンバー取込メニュー表示"
    },
    "/company/member/index": {
      "uri": "/company/member/index",
      "task": "メンバー一覧"
    },
    "/company/member/detail/": {
      "uri": "/company/member/detail/",
      "task": "メンバー詳細"
    },
    "/company/message/index": {
      "uri": "/company/message/index",
      "task": "メッセージ一覧"
    },
    "/company/message/detail/": {
      "uri": "/company/message/detail/",
      "task": "メッセージ詳細"
    },
    "/company/notification": {
      "uri": "/company/notification",
      "task": "お知らせ一覧表示"
    },
    "/company/notification/": {
      "uri": "/company/notification/",
      "task": "お知らせ詳細表示"
    },
    "/company/tutorial/": {
      "uri": "/company/tutorial/",
      "task": "チュートリアル表示"
    },
    "/company/help/faq/": {
      "uri": "/company/help/faq/",
      "task": "よくある質問"
    },
    "/company/report/index": {
      "uri": "/company/report/index",
      "task": "回答レポート表示"
    },
    "/company/setting/account": {
      "uri": "/company/setting/account",
      "task": "アカウント一覧表示"
    },
    "/company/setting/enq": {
      "uri": "/company/setting/enq",
      "task": "設定メニュー表示"
    },
    "/company/setting/enq/annual": {
      "uri": "/company/setting/enq/annual",
      "task": "入社１年後活躍アンケート設定"
    },
    "/company/setting/enq/monthly": {
      "uri": "/company/setting/enq/monthly",
      "task": "月次アンケートメール設定"
    },
    "/company/setting/enq/notification": {
      "uri": "/company/setting/enq/notification",
      "task": "通知オプション表示"
    },
    "/company/setting/enq/monthly/thanks": {
      "uri": "/company/setting/enq/monthly/thanks",
      "task": "サンクスメールテンプレート画面表示"
    },
    "/company/setting/enq/notification/app": {
      "uri": "/company/setting/enq/notification/app",
      "task": "アプリ連携メンバー一覧表示"
    },
    "/company/setting/enq/notification/line": {
      "uri": "/company/setting/enq/notification/line",
      "task": "LINE連携メンバー一覧表示"
    },
    "/company/setting/enq/notification/lineworks": {
      "uri": "/company/setting/enq/notification/lineworks",
      "task": "LINE Works連携メンバー一覧表示"
    },
    "/company/setting/enq/notification/slack": {
      "uri": "/company/setting/enq/notification/slack",
      "task": "Slackメンバー一覧表示"
    },
    "/company/setting/enq/notification/sms": {
      "uri": "/company/setting/enq/notification/sms",
      "task": "SMS連携メンバー一覧表示"
    },
    "/company/setting/import": {
      "uri": "/company/setting/import",
      "task": "エンサービス連携メニュー表示"
    },
    "/company/setting/message/index": {
      "uri": "/company/setting/message/index",
      "task": "メンバーメッセージ表示"
    },
    "/company/setting/security": {
      "uri": "/company/setting/security",
      "task": "セキュリティ設定表示"
    },
    "/company/setting/talent_import": {
      "uri": "/company/setting/talent_import",
      "task": "TAメンバー取り込み"
    },
    "/company/setting/message/create": {
      "uri": "/company/setting/message/create",
      "task": "メッセージテンプレート設定表示"
    },
    "/company/setting/message": {
      "uri": "/company/setting/message",
      "task": "メッセージテンプレート一覧"
    },
    "/company/temp_member/": {
      "uri": "/company/temp_member/",
      "task": "エン連携サービスメンバー"
    }
  }
}
