import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { TalentUnlinkMember } from '~/types/talentMember'

export type Methods = DefineMethods<{
  get: {
    resBody: TalentUnlinkMember[]
  }
}>

const talent_members = [
  {
    id: 176,
    last_name: 'テスト',
    first_name: '太郎',
    email: 'talent_members@en-japan.com',
    TalentMember: 0,
  },
  {
    id: 177,
    last_name: 'テスト',
    first_name: '次郎',
    email: 'talent_members02@gmail.com',
    TalentMember: 0,
  },
  {
    id: 178,
    last_name: 'テスト',
    first_name: '三郎',
    email: 'talent_members03@gmail.com',
    TalentMember: 0,
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: talent_members,
  }),
})
