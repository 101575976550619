import type { DefineMethods } from 'aspida'
import type { Notification } from '~/types/notification'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    query: {
      limit: number
      offset: number
    }

    resBody: {
      count: number
      notifications: Notification[]
    }
  }
}>

export const notificationsMockData = [
  {
    id: 1,
    date_published: '2024/12/03',
    title:
      'HR OnBoardが「BOXIL SaaS AWARD Winter 2024」離職防止ツール部門で7つの賞を受賞しました！',
    body: 'いつもHR OnBoard をご利用いただき誠にありがとうございます。\n<br />この度、当サービスがスマートキャンプ株式会社主催の「BOXIL SaaS AWARD Winter 2024」の離職防止ツール部門において、7つの賞を受賞しましたのでお知らせいたします。\n<br />受賞内容は以下の通りです。\n「Good Service」\n「料金の妥当性No.1」\n「サポートの品質No.1」\n「営業担当の印象No.1」\n「サービスの安定性No.1」\n「お役立ち度No.1」\n「機能満足度No.1」\n<br />これらの受賞は、皆様からいただいた貴重な口コミとご意見のおかげで実現しました。心より感謝申し上げます。\n<br />▽「BOXIL SaaS AWARD Winter 2024」の詳細、選考基準は、公式サイトでご覧いただけます。\n<a href="https://boxil.jp/awards/2024-winter/" target="_blank">https://boxil.jp/awards/2024-winter/</a>\n<br />また、この受賞についてのプレスリリースも発表しております。詳細はそちらもぜひご覧ください。\n<br />▽プレスリリース\n<a href="https://corp.en-japan.com/newsrelease/2024/39744.html" target="_blank">https://corp.en-japan.com/newsrelease/2024/39744.html</a>\n<br /><br />今後とも、当サービスをどうぞよろしくお願い申し上げます。<br />\n',
    is_latest: true,
    is_draft: false,
    created_at: '2024/12/03',
    updated_at: '2024/12/03',
  },
  {
    id: 2,
    date_published: '2018/12/07',
    title: '【有料申込スケジュール】メンバーを追加したい企業様へのお知らせ',
    body: '来月よりアンケート配信を開始したいメンバー枠のお申し込みを承ります。\n～ 料金\n1人あたり10,000円(税抜)/最大12ヶ月\n※初回配信日は10/1以降がお選びいただけます。\n',
    is_latest: false,
    is_draft: false,
    created_at: '2018/12/07',
    updated_at: '2018/12/07',
  },
  {
    id: 3,
    date_published: '2018/11/21',
    title:
      '【システムメンテナンス日時変更】9月18日（水）18:00～22:00　ヘルプページへのアクセスを一時停止いたします',
    body: 'いつもHR OnBoardをご利用いただき誠にありがとうございます。\nヘルプページのデザインリニューアルに伴い、HR OnBoardヘルプページへのアクセスを一時停止させていただきます。\n以前お知らせしていた日程から、実施時間を以下に変更いたしました。\n',
    is_latest: false,
    is_draft: false,
    created_at: '2018/11/21',
    updated_at: '2018/11/21',
  },
  {
    id: 4,
    date_published: '2018/08/22',
    title: '【機能更新】機能の更新を行ないました。',
    body: 'HR OnBoard事務局です。\nいつもHR OnBoardをご利用いただき誠にありがとうございます。\nよりいっそう便利にご利用いただけるよう、機能の更新を行ないました。\n',
    is_latest: false,
    is_draft: true,
    created_at: '2018/07/22',
    updated_at: '2018/08/22',
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    query: {
      limit: 3,
      offset: 0,
    },
    resBody: {
      count: notificationsMockData.length,
      notifications: notificationsMockData,
    },
  }),
})
