<template>
  <div class="app-lp-footer">
    <div class="container">
      <div class="top">
        <div class="logo">
          <div class="main">
            <app-img
              src="/logo/logo_hrob_white.svg"
              alt="hrob-logo"
              width="275"
            />
          </div>
        </div>
        <div class="menu">
          <ul class="left">
            <li class="item">
              <nuxt-link
                to="/features"
                class="link"
                >HR OnBoardでできること</nuxt-link
              >
            </li>
            <li class="item">
              <nuxt-link
                to="/performance"
                class="link"
                >実績紹介</nuxt-link
              >
            </li>
            <li class="item">
              <nuxt-link
                to="/case"
                class="link"
                >導入事例</nuxt-link
              >
            </li>
            <li class="item">
              <nuxt-link
                to="/introduction"
                class="link"
                >導入の流れ</nuxt-link
              >
            </li>
            <li class="item">
              <nuxt-link
                to="#price"
                class="link"
                >料金プラン</nuxt-link
              >
            </li>
          </ul>
          <ul class="middle">
            <li class="item">
              <nuxt-link
                class="link"
                to="/news"
                >お知らせ</nuxt-link
              >
            </li>
            <li class="item">
              <a
                href="https://corp.en-japan.com/services/story_hronboard.html"
                class="link"
                target="_blank"
                rel="noopener noreferrer"
                ><div class="ex-link">
                  <div>サービスに込めた想い</div>
                  <app-img
                    src="/lp/icon_external_link.svg"
                    alt="外部リンクアイコン"
                    class="icon"
                  /></div
              ></a>
            </li>
            <li class="item">
              <a
                href="https://lp.on-board.io/webinar2020.html"
                class="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="ex-link">
                  <div>セミナー情報</div>
                  <app-img
                    src="/lp/icon_external_link.svg"
                    alt="外部リンクアイコン"
                    class="icon"
                  /></div
              ></a>
            </li>
          </ul>
          <ul class="right">
            <li class="item">
              <span
                class="link"
                @click="openTryDialog"
                >無料で試す</span
              >
            </li>
            <li class="item">
              <a
                :href="$t('uri.contact')"
                class="link"
                target="_blank"
                rel="noopener noreferrer"
                >お問い合わせ</a
              >
            </li>
            <li class="item">
              <nuxt-link
                to="/auth/login/"
                class="link"
                >ログイン</nuxt-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <nuxt-link
            to="/terms#privacy"
            target="_blank"
            class="link"
            >プライバシーポリシー</nuxt-link
          >
          <nuxt-link
            to="/terms"
            target="_blank"
            class="link"
            >利用規約</nuxt-link
          >
        </div>
        <div class="right">Copyright© en Japan Inc. All Rights Reserved.</div>
      </div>
      <transition>
        <div
          v-if="isScrolled"
          class="ab-button"
        >
          <nav class="buttons">
            <div class="item item-1">
              <div
                class="link"
                @click="openTryDialog"
              >
                <div class="imgwrap">
                  <app-img
                    src="/ui/appLpFooter/btn_freetrial.svg"
                    alt="btn-freetrial"
                    height="170"
                    width="170"
                  />
                </div>
              </div>
            </div>
            <div class="item item-2">
              <a
                :href="$t('uri.contact')"
                class="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="imgwrap">
                  <app-img
                    src="/ui/appLpFooter/btn_inquiry.svg"
                    alt="btn-inquiry"
                    height="170"
                    width="170"
                  />
                </div>
              </a>
            </div>
            <div class="item item-3">
              <a
                v-scroll-to="'body'"
                class="link"
              >
                <div class="imgwrap">
                  <app-img
                    src="/ui/appLpFooter/btn_backtotop.svg"
                    alt="btn-backtotop"
                    height="170"
                    width="170"
                  />
                </div>
              </a>
            </div>
          </nav>
        </div>
      </transition>
      <app-lp-try-dialog
        v-if="isVisibleTryDialog"
        @close="closeTryDialog"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const { isScrolled } = useScrollEffect()

const isVisibleTryDialog = ref(false)
const openTryDialog: () => void = () => {
  isVisibleTryDialog.value = true
}
const closeTryDialog: () => void = () => {
  isVisibleTryDialog.value = false
}
</script>

<style lang="scss" scoped>
.app-lp-footer {
  font-size: max(0.8333vw, 12px);
  font-weight: $font-weight-bold;
  color: $white-color;
  background-color: $lp-color-16;

  @include tablet {
    font-size: max(0.8333vw, 16px);
  }

  > .container {
    @include tablet {
      margin: 0 16px;
    }
    > .bottom {
      display: flex;
      justify-content: space-between;
      padding: 24px 0;

      @include tablet {
        flex-direction: column;
        padding: 0;
      }

      > .left {
        font-weight: $font-weight-bold;

        > .link {
          @include tablet {
            display: block;
            padding: 16px 13px;
          }

          &:not(:first-child) {
            margin-left: 58px;

            @include tablet {
              margin-left: 0;
            }
          }
        }
      }

      > .right {
        font-size: max(0.7292vw, 10px);
        color: $lp-color-1;

        @include tablet {
          padding: 24px 0;
          font-size: $font-size-14;
        }
      }
    }

    > .ab-button {
      position: fixed;
      right: 78px;
      bottom: 34px;
      z-index: 100;

      @include pc {
        right: 0;
      }

      @include tablet {
        bottom: 0;
      }

      > .buttons {
        display: flex;
        justify-content: space-between;

        > .item {
          width: 115px;
          height: 115px;
          overflow: hidden;

          > .link {
            display: block;
            cursor: pointer;

            > .imgwrap {
              margin-top: -28px;
              margin-left: -28px;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        > .item-1,
        > .item-2 {
          @include tablet {
            display: none;
          }
        }
      }
    }

    > .top {
      display: flex;
      padding-top: 72px;

      @include tablet {
        flex-direction: column;
        padding-top: 48px;
      }

      > .logo {
        width: 30%;
        flex-shrink: 0;

        @include tablet {
          width: 100%;
        }

        > .main {
          display: flex;

          @include tablet {
            justify-content: center;
          }
        }
      }

      > .menu {
        display: flex;
        width: 70%;

        @include tablet {
          flex-direction: column;
          width: 100%;
          margin-top: 40px;
        }

        > .left,
        > .middle,
        > .right {
          padding: 0;
          margin: 0;
          width: 33%;

          @include tablet {
            width: 100%;
          }

          > .item {
            padding: 0;
            margin: 0;
            list-style: none;

            > .link {
              display: block;
              padding: 20px;
              cursor: pointer;

              @include tablet {
                padding: 16px 13px;
              }

              > .ex-link {
                display: flex;
                align-items: center;

                > .icon {
                  margin-left: 8px;
                }
              }
            }
          }
        }

        > .left {
          flex-shrink: 0;
        }
      }
    }
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
