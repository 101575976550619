import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MemberMonthlyEnq } from '~/types/memberMonthlyEnq'

export type Methods = DefineMethods<{
  get: {
    resBody: MemberMonthlyEnq[]
    query: { id: number }
  }
}>
const mockAnswers: Record<number, MemberMonthlyEnq[]> = {
  1: [
    {
      id: 1,
      month: '2021-02-01',
      monthly_count: 3,
      progress_status: 1,
      answer_json:
        // eslint-disable-next-line no-multi-str
        '[ \
          {"answer1": "1"}, \
          {"answer2": "1"}, \
          {"answer3": "1"}, \
          {"question": "最近の調子はどうでしょうか？", "answer": "今月忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"}, \
          {"question": "職場の雰囲気になれたでしょうか？", "answer": "はい。"}, \
          {"question": "なにか不安なことありますか？", "answer": "リモート勤務で職場の雰囲気が分からない"}, \
          {"text": "今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"} \
        ]',
      question1: 'この1ヶ月、担当業務が身についてきた実感がありますか？',
      answer1: 'ややそう思う',
      color1: '#d34c52',
      question2:
        'この1ヶ月、仕事の相談や報告をする時に直属の上司は話しかけやすかったですか？',
      answer2: 'そう思う',
      color2: '#efad3c',
      question3: 'この1ヶ月の業務量はいかがでしたか？',
      answer3: 'やや多い',
      color3: '#7947ce',
      answer_text: `実際行う業務イメージは少し違うので、自分が欲しい経験を積むことができなさそうに思いました。
定型で重複作業がほとんどで、自分の発想で試行錯誤を行ったり改善する機会は現場であんまりないです。
もちろん新人としてしっかり基本業務を身に着けていく必要性は存じています。
もし他の部署に興味はある場合、社内部署転換制度はありますでしょうか？
また、実現するために、どのような実績や条件などが必要なのか教えていただけると助かります。`,
      condition_type: 3,
      dt_answered: '2022-02-10',
      explanation:
        '業務量が「多い」または「やや多い」と回答しています。このメンバーは仕事の負荷量に対するストレス耐性が低いため、注意が必要です。また、フリーコメントにフォローが必要な内容が含まれています。',
      action:
        '任された仕事について体力的、精神的な負担がかかりすぎていないかを本人に確認し、必要に応じてサポートを行う必要があります。また、フリーコメントに記入された内容も踏まえて本人へのフォローを検討してください。直属の上司との関係性は良好のため、上司に対応を依頼することも有効です。',
      is_changed_condition_by_ta: true,
      created_at: '2022-02-31',
      updated_at: '2022-02-31',
      emotion_answer_id: 1,
      feedback_type: null,
      need_follow_tag: true,
      question_tag: true,
      neg_clauses: ['改善する機会は現場であんまりないです。'],
      care_word_list: [
        '自分が欲しい経験を積むことができなさそうに思いました。',
      ],
      question_list: [
        'もし他の部署に興味はある場合、社内部署転換制度はありますでしょうか？',
      ],
    },
    {
      id: 2,
      month: '2021-01-01',
      monthly_count: 2,
      progress_status: 1,
      answer_json:
        // eslint-disable-next-line no-multi-str
        '[ \
          {"answer1": "1"}, \
          {"answer2": "1"}, \
          {"answer3": "1"}, \
          {"question": "最近の調子はどうでしょうか？", "answer": "今月忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"}, \
          {"question": "職場の雰囲気になれたでしょうか？", "answer": "はい。"}, \
          {"question": "なにか不安なことありますか？", "answer": "リモート勤務で職場の雰囲気が分からない"}, \
          {"text": "今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"} \
        ]',
      question1: '社員や職場の雰囲気は入社前のイメージと比べていかがですか？',
      answer1: 'ややイメージ通り',
      color1: '#ea8039',
      question2: '職場のルールや仕事の進め方には慣れましたか？',
      answer2: '慣れた！',
      color2: '#d34c52',
      question3:
        'この1ヶ月、仕事の相談や報告をする時に直属の上司は話しやすかったですか？',
      answer3: 'やや話しやすい',
      color3: '#efad3c',
      answer_text: `実際行う業務イメージは少し違うので、自分が欲しい経験を積むことができなさそうに思いました。
定型で重複作業がほとんどで、自分の発想で試行錯誤を行ったり改善する機会は現場であんまりないです。
もちろん新人としてしっかり基本業務を身に着けていく必要性は存じています。
もし他の部署に興味はある場合、社内部署転換制度はありますでしょうか？
また、実現するために、どのような実績や条件などが必要なのか教えていただけると助かります。`,
      condition_type: 3,
      dt_answered: '2022-01-10',
      explanation:
        '選択肢回答からは順調に仕事に取り組めている様子が伺えますが、フリーコメントにフォローが必要な内容が含まれています。',
      action:
        'フリーコメントに記入された内容を踏まえて本人へのフォローを検討してください。',
      created_at: '2022-01-31',
      updated_at: '2022-01-31',
      emotion_answer_id: 1,
      feedback_type: null,
      need_follow_tag: true,
      question_tag: true,
      neg_clauses: ['改善する機会は現場であんまりないです。'],
      care_word_list: [
        '自分が欲しい経験を積むことができなさそうに思いました。',
      ],
      question_list: [
        'もし他の部署に興味はある場合、社内部署転換制度はありますでしょうか？',
      ],
    },
    {
      id: 3,
      month: '2020-12-01',
      monthly_count: 1,
      progress_status: 1,
      answer_json:
        // eslint-disable-next-line no-multi-str
        '[ \
          {"answer1": "1"}, \
          {"answer2": "1"}, \
          {"answer3": "1"}, \
          {"question": "最近の調子はどうでしょうか？", "answer": "今月忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"}, \
          {"question": "職場の雰囲気になれたでしょうか？", "answer": "はい。"}, \
          {"question": "なにか不安なことありますか？", "answer": "リモート勤務で職場の雰囲気が分からない"}, \
          {"text": "今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"} \
        ]',
      question1: '職場にはなれましたか',
      answer1: '当てはまる',
      color1: '#d34c52',
      question2:
        '上長と意思疎通をしっかり取り、チームの目標を理解できていますか？',
      answer2: '当てはまる',
      color2: '#efad3c',
      question3: '職場には慣れましたか？',
      answer3: '当てはまらない',
      color3: '#7947ce',
      answer_text:
        '今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がする。',
      condition_type: 3,
      dt_answered: '2021-12-10',
      explanation:
        '社員や職場の雰囲気について入社前とのイメージギャップを感じているようです。',
      action:
        'どのような理由で上司に話しかけにくいと感じているのか、入社者本人に確認しましょう。その上で適切なサポートを行う必要があります。',
      created_at: '2021-12-31',
      updated_at: '2021-12-31',
      emotion_answer_id: 2,
      feedback_type: 1,
      need_follow_tag: true,
      question_tag: false,
      neg_clauses: ['忙しすぎ'],
      care_word_list: [],
      question_list: [],
    },
  ],
  2: [
    {
      id: 1,
      month: '2024-10-01',
      monthly_count: 3,
      progress_status: 1,
      answer_json:
        // eslint-disable-next-line no-multi-str
        '[ \
          {"answer1": "1"}, \
          {"answer2": "1"}, \
          {"answer3": "1"}, \
          {"question": "最近の調子はどうでしょうか？", "answer": "今月忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"}, \
          {"question": "職場の雰囲気になれたでしょうか？", "answer": "はい。"}, \
          {"question": "なにか不安なことありますか？", "answer": "リモート勤務で職場の雰囲気が分からない"}, \
          {"text": "今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"} \
        ]',
      question1: 'この1ヶ月、担当業務が身についてきた実感がありますか？',
      answer1: 'そう思う',
      color1: '#d34c52',
      question2:
        'この1ヶ月、仕事の相談や報告をする時に直属の上司は話しかけやすかったですか？',
      answer2: 'そう思わない',
      color2: '#efad3c',
      question3: 'この1ヶ月の業務量はいかがでしたか？',
      answer3: 'やや少ない',
      color3: '#7947ce',
      answer_text: `部内の先輩社員はみな多忙で相談がしにくい状況です。その影響で、私への業務分担も少なくなり、結果的に放置されることが多いです。`,
      condition_type: 3,
      dt_answered: '2024-10-10',
      explanation:
        '業務量が「少ない」または「やや少ない」と回答しています。このメンバーは責任や期待に応えるような多くの仕事にチャレンジしたい考えを持っているため、注意が必要です。また、フリーコメントにフォローが必要な内容が含まれています。',
      action:
        '業務が少ないことで仕事のやる気が低下していないかを本人に確認し、必要に応じてサポートを行う必要があります。また、フリーコメントに記入された内容も踏まえて本人へのフォローを検討してください。直属の上司との関係性は良好のため、上司に対応を依頼することも有効です。',
      is_changed_condition_by_ta: true,
      created_at: '2022-02-31',
      updated_at: '2022-02-31',
      emotion_answer_id: 1,
      feedback_type: null,
      need_follow_tag: true,
      question_tag: false,
      neg_clauses: ['部内の先輩社員はみな多忙で相談がしにくい'],
      care_word_list: [],
      question_list: [],
    }
  ],
  3: [
    {
      id: 1,
      month: '2021-01-01',
      monthly_count: 2,
      progress_status: 1,
      answer_json:
        // eslint-disable-next-line no-multi-str
        '[ \
          {"answer1": "1"}, \
          {"answer2": "1"}, \
          {"answer3": "1"}, \
          {"question": "最近の調子はどうでしょうか？", "answer": "今月忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"}, \
          {"question": "職場の雰囲気になれたでしょうか？", "answer": "はい。"}, \
          {"question": "なにか不安なことありますか？", "answer": "リモート勤務で職場の雰囲気が分からない"}, \
          {"text": "今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"} \
        ]',
      question1: '社員や職場の雰囲気は入社前のイメージと比べていかがですか？',
      answer1: 'ややイメージ通り',
      color1: '#ea8039',
      question2: '職場のルールや仕事の進め方には慣れましたか？',
      answer2: '慣れた！',
      color2: '#d34c52',
      question3:
        'この1ヶ月、仕事の相談や報告をする時に直属の上司は話しやすかったですか？',
      answer3: 'やや話しやすい',
      color3: '#efad3c',
      answer_text: `実際行う業務イメージは少し違うので、自分が欲しい経験を積むことができなさそうに思いました。定型で重複作業がほとんどで、自分の発想で試行錯誤を行ったり改善する機会は現場であんまりないです。
もちろん新人としてしっかり基本業務を身に着けていく必要性は存じています。
もし他の部署に興味はある場合、社内部署転換制度はありますでしょうか？
また、実現するために、どのような実績や条件などが必要なのか教えていただけると助かります。`,
      condition_type: 3,
      dt_answered: '2022-01-10',
      explanation:
        '選択肢回答からは順調に仕事に取り組めている様子が伺えますが、フリーコメントにフォローが必要な内容が含まれています。',
      action:
        'フリーコメントに記入された内容を踏まえて本人へのフォローを検討してください。',
      created_at: '2022-01-31',
      updated_at: '2022-01-31',
      emotion_answer_id: 1,
      feedback_type: null,
      need_follow_tag: true,
      question_tag: true,
      neg_clauses: ['改善する機会は現場であんまりないです。'],
      care_word_list: [
        '自分が欲しい経験を積むことができなさそうに思いました。',
      ],
      question_list: [
        'もし他の部署に興味はある場合、社内部署転換制度はありますでしょうか？',
      ],
    },
    {
      id: 2,
      month: '2020-12-01',
      monthly_count: 1,
      progress_status: 1,
      answer_json:
        // eslint-disable-next-line no-multi-str
        '[ \
          {"answer1": "1"}, \
          {"answer2": "1"}, \
          {"answer3": "1"}, \
          {"question": "最近の調子はどうでしょうか？", "answer": "今月忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"}, \
          {"question": "職場の雰囲気になれたでしょうか？", "answer": "はい。"}, \
          {"question": "なにか不安なことありますか？", "answer": "リモート勤務で職場の雰囲気が分からない"}, \
          {"text": "今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がするが、一時的なものなのでしょうか。"} \
        ]',
      question1: '職場にはなれましたか',
      answer1: '当てはまる',
      color1: '#d34c52',
      question2:
        '上長と意思疎通をしっかり取り、チームの目標を理解できていますか？',
      answer2: '当てはまる',
      color2: '#efad3c',
      question3: '職場には慣れましたか？',
      answer3: '当てはまらない',
      color3: '#7947ce',
      answer_text:
        '今月も忙しすぎて趣味の時間が取れなかった。職場はそんなに忙しくないと聞いていた気がする。',
      condition_type: 3,
      dt_answered: '2020-12-10',
      explanation:
        '社員や職場の雰囲気について入社前とのイメージギャップを感じているようです。',
      action:
        'どのような理由で上司に話しかけにくいと感じているのか、入社者本人に確認しましょう。その上で適切なサポートを行う必要があります。',
      created_at: '2021-12-31',
      updated_at: '2021-12-31',
      emotion_answer_id: 2,
      feedback_type: 1,
      need_follow_tag: true,
      question_tag: false,
      neg_clauses: ['忙しすぎ'],
      care_word_list: [],
      question_list: [],
    },
  ],
}

export default mockMethods<Methods>({
  get: ({ query }) => {
    const id = query.id
    const resultAnswers = mockAnswers[id] || mockAnswers[3]
    return {
      status: 200,
      resBody: resultAnswers,
    }
  },
})
