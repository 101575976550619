import type { DefineMethods } from 'aspida'
import type { Notification } from '~/types/notification'
import { mockMethods } from 'aspida-mock'
import { notificationsMockData } from '../index'

export type Methods = DefineMethods<{
  get: {
    resBody: Notification
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: notificationsMockData[0],
  }),
})
