import type { DefineMethods } from 'aspida'
import type { Code } from '~/types/member/code'
import { mockMethods } from 'aspida-mock'
import { getDate } from '../search/index'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: Code
    }
  }
}>

const mockMembers: Record<number, Code> = {
  8540942181: {
    id: 1,
    company_id: 1,
    code: 8540942181,
    lastname: '高橋',
    firstname: '理沙',
    lastname_kana: 'タカハシ',
    firstname_kana: 'リサ',
    employee_code: '100028',
    employing_type: 1,
    working_status: 1,
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    gender: 2,
    birth_year: 2001,
    birth_month: 12,
    birth_day: 7,
    mobile_tel_number: '04077779999',
    email: 'r-takahashi@example.com',
    delivery_date: `${getDate(5)}`,
    profession: 'ディレクター',
    senior_id: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    date_contracted: `${getDate(4)}`,
    has_image: 1,
    image_uri: '/img/mock/user1.png',
    condition_type: 1,
    progress_status: 1,
    interview_status: 0,
    importance: 1,
    interview_due_date: '2020/12/31',
    job_change_count: 0,
    working_years: 0,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: '',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    is_stopped_send_questions_mail: 0,
    mail_language: 0,
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 1,
    anniv_renewal_sent_date: '2020-01-31',
    anniv_sent: 1,
    age: 21,
    hr_user_id: 1,
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    talent_member_id: 11,
    talent_status: 1,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
  },
  9993434304: {
    id: 2,
    company_id: 1,
    code: 9993434304,
    department: {
      id: 2,
      full_path: 'デモ株式会社 > 人事部',
      short_name: '人事部',
    },
    email: 'y-miyazaki@example.com',
    image_uri: '/img/mock/user2.png',
    date_contracted: `${getDate(4)}`,
    condition_type: 3,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '宮崎',
    firstname: '裕美',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 1,
    interview_due_date: '2020-12-31',
    importance: 3,
    employing_type: 1,
    working_status: 1,
    gender: 2,
    birth_year: 1989,
    birth_month: 11,
    birth_day: 8,
    initial_evaluation: 1,
    is_newbie: 0,
    progress_status: 1,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 1,
    talent_member_id: 10,
    lastname_kana: 'ミヤザキ',
    firstname_kana: 'ユミ',
    employee_code: '100027',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(5)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  6819148994: {
    id: 3,
    company_id: 1,
    code: 6819148994,
    department: {
      id: 3,
      full_path: 'デモ株式会社 > 開発部',
      short_name: '開発部',
    },
    email: 's-nishida@example.com',
    image_uri: '/img/mock/user3.png',
    date_contracted: `${getDate(4)}`,
    condition_type: 2,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '西田',
    firstname: '聡',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 2,
    interview_due_date: '2024-10-01',
    importance: 2,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 1994,
    birth_month: 9,
    birth_day: 9,
    initial_evaluation: 1,
    is_newbie: 0,
    progress_status: 2,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 0,
    talent_member_id: 9,
    lastname_kana: 'ニシダ',
    firstname_kana: 'サトシ',
    employee_code: '100026',
    date_resigned: '2024-04-01',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(5)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2025-04-01',
    date_quit: '',
    is_deleted: 0,
    created_at: '2024-04-01',
    updated_at: '2024-04-01',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  1596494267: {
    id: 4,
    company_id: 1,
    code: 1596494267,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    email: 'd-kawasaki@example.com',
    image_uri: '/img/mock/user4.png',
    date_contracted: `${getDate(4)}`,
    condition_type: 2,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '川崎',
    firstname: '大治郎',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2024-10-01',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 1968,
    birth_month: 2,
    birth_day: 13,
    initial_evaluation: 1,
    is_newbie: 0,
    progress_status: 1,
    age: 56,
    is_stopped_send_questions_mail: 1,
    talent_status: 0,
    talent_member_id: 8,
    lastname_kana: 'カワサキ',
    firstname_kana: 'ダイジロウ',
    employee_code: '100025',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(5)}`,
    profession: 'CTO',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 6,
    working_years: 34,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'CTO',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  7140965380: {
    id: 5,
    company_id: 1,
    code: 7140965380,
    lastname: '田中',
    firstname: '太郎',
    lastname_kana: 'タナカ',
    firstname_kana: 'タロウ',
    employee_code: '100024',
    employing_type: 1,
    working_status: 1,
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    gender: 1,
    birth_year: 2010,
    birth_month: 10,
    birth_day: 2,
    mobile_tel_number: '04077779999',
    email: 't-tanaka@example.com',
    delivery_date: `${getDate(11)}`,
    profession: 'ディレクター',
    senior_id: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    date_contracted: `${getDate(10, 1, true)}`,
    has_image: 1,
    image_uri: '/img/mock/user5.png',
    condition_type: 1,
    progress_status: 1,
    interview_status: 1,
    importance: 1,
    interview_due_date: '2020/12/31',
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    is_stopped_send_questions_mail: 0,
    mail_language: 0,
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 1,
    anniv_renewal_sent_date: '2020-01-31',
    anniv_sent: 1,
    age: 22,
    hr_user_id: 23,
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    talent_member_id: 1,
    talent_status: 0,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
    department: {
      id: 1,
      name: '東京営業所',
      full_path: '営業部 > 営業第二課 > 東京営業所',
    },
  },
  8515856096: {
    id: 6,
    code: 8515856096,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    email: 'm-hoshino@example.com',
    image_uri: '/img/mock/user6.png',
    date_contracted: `${getDate(10, 1, true)}`,
    condition_type: 2,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '星野',
    firstname: '美希',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 2,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    progress_status: 2,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 0,
    talent_member_id: 6,
    lastname_kana: 'ホシノ',
    firstname_kana: 'ミキ',
    company_id: 1,
    employee_code: '100023',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(11)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  7522851289: {
    id: 7,
    code: 7522851289,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    email: 'k-takeuchi@example.com',
    image_uri: '/img/mock/user7.png',
    date_contracted: `${getDate(10, 1, true)}`,
    condition_type: 3,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '竹内',
    firstname: '浩一',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    progress_status: 2,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 0,
    talent_member_id: 5,
    lastname_kana: 'タケウチ',
    firstname_kana: 'コウイチ',
    company_id: 1,
    employee_code: '100022',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(11)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  6467494799: {
    id: 8,
    code: 6467494799,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    email: 'f-umebayashi@example.com',
    image_uri: '/img/mock/user8.png',
    date_contracted: `${getDate(10, 1, true)}`,
    condition_type: 1,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '梅林',
    firstname: '文哉',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    progress_status: 2,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 0,
    talent_member_id: 4,
    lastname_kana: 'ウメバヤシ',
    firstname_kana: 'フミヤ',
    company_id: 1,
    employee_code: '100021',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(11)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  6964704721: {
    id: 9,
    code: 6964704721,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    email: 'h-endo@example.com',
    image_uri: '/img/mock/user9.png',
    date_contracted: `${getDate(10, 1, true)}`,
    condition_type: 1,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '遠藤',
    firstname: 'ひかり',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 2,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    progress_status: 2,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 0,
    talent_member_id: 3,
    lastname_kana: 'エンドウ',
    firstname_kana: 'ヒカリ',
    company_id: 1,
    employee_code: '100020',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(11)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  6265434524: {
    id: 10,
    code: 6265434524,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    email: 't-uno@example.com',
    image_uri: '/img/mock/user12.png',
    date_contracted: `${getDate(10, 1, true)}`,
    condition_type: 1,
    anniv_sent: 1,
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 0,
    lastname: '宇野',
    firstname: '達朗',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    progress_status: 2,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 0,
    talent_member_id: 2,
    lastname_kana: 'ウノ',
    firstname_kana: 'タツロウ',
    company_id: 1,
    employee_code: '100019',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(11)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
  2489945860: {
    id: 11,
    code: 2489945860,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    email: 'y-suzuki@example.com',
    image_uri: '/img/mock/user11.png',
    date_contracted: `${getDate(10, 1, true)}`,
    condition_type: 1,
    anniv_sent: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    lastname: '鈴木',
    firstname: '陽介',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: 1,
    progress_status: 2,
    age: 21,
    is_stopped_send_questions_mail: 0,
    talent_status: 0,
    talent_member_id: 1,
    lastname_kana: 'スズキ',
    firstname_kana: 'ヨウスケ',
    company_id: 1,
    employee_code: '100018',
    date_resigned: '2020-09-17',
    resign_reason: ['7'],
    resign_reason_text: '転職のため',
    mobile_tel_number: '04077779999',
    delivery_date: `${getDate(11)}`,
    profession: 'ディレクター',
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: 1,
    route_employed_detail: '',
    has_image: 1,
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    mail_language: 0,
    anniv_renewal_sent_date: '2020-01-31',
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    using_slack: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    sms_status: 2,
    line_user_id: '',
    line_status: 2,
    line_works_id: 1,
    line_works_send_success_flag: 0,
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    app_status: 2,
    has_answer: true,
  },
}

export default mockMethods<Methods>({
  get: ({path}) => {
    const codeString = path.split('/').pop()
    const code = codeString ? parseInt(codeString) : NaN
    const result = mockMembers[code] || mockMembers[7140965380]
    return {
      status: 200,
      resBody: {
        result,
      },
    }
  }
})
