import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: Res
  }
}>

type Res = {
  title: string
  body: string
  image_uri: string
  external_link: string
  updated_at: string
}

const res = {
  title:
    'HR OnBoardが「BOXIL SaaS AWARD Winter 2024」離職防止ツール部門で7つの賞を受賞しました！',
  body:
    'いつもHR OnBoard をご利用いただき誠にありがとうございます。\n' +
    ' <br>この度、当サービスがスマートキャンプ株式会社主催の「BOXIL SaaS AWARD Winter 2024」の離職防止ツール部門において、7つの賞を受賞しましたのでお知らせいたします。</br>\n' +
    ' <br>詳細はお知らせからご覧ください。</br>',
  image_uri:
    'thumbnail_80ghVe9HXlW7HhEwp1bRKeGeXxHsg6B3PtAkB5ma0CqlXga1ZyRFIChq4gQSWEV6.png',
  external_link: 'https://display.on-board.io/company/notification/1',
  updated_at: '2024-12-03 10:00:10',
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: res,
  }),
})
