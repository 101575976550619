import type { AspidaClient } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_1gzr4nz } from './v3/actionlogs';
import type { Methods as Methods_d8jwkp } from './v3/actionlogs/_id@number';
import type { Methods as Methods_vc4hf7 } from './v3/anniversaries/report';
import type { Methods as Methods_wp9j9l } from './v3/anniversaries/xlsx-new';
import type { Methods as Methods_11c10bm } from './v3/anniversaries/xlsx';
import type { Methods as Methods_1nbrtha } from './v3/answers/chart';
import type { Methods as Methods_10ff740 } from './v3/answers/report';
import type { Methods as Methods_hu56pj } from './v3/answers/report/calendar';
import type { Methods as Methods_kvrrej } from './v3/answers/report/condition-calendar';
import type { Methods as Methods_9zpadk } from './v3/answers/report/condition-count';
import type { Methods as Methods_mbyt5s } from './v3/answers/report/condition-detail';
import type { Methods as Methods_1cukf5o } from './v3/answers/report/count';
import type { Methods as Methods_pg0d25 } from './v3/answers/xlsx';
import type { Methods as Methods_1ha0uec } from './v3/avatars/_url@string';
import type { Methods as Methods_vu5m95 } from './v3/companies';
import type { Methods as Methods_80nipv } from './v3/companies/annual_confirmation';
import type { Methods as Methods_mlpqqx } from './v3/companies/annual_notification';
import type { Methods as Methods_1ohkhcu } from './v3/companies/pages';
import type { Methods as Methods_7js5tf } from './v3/companies/settings/initial_mails';
import type { Methods as Methods_1rlba1z } from './v3/companies/settings/member_list_count';
import type { Methods as Methods_5252iq } from './v3/companies/settings/monthly_notifications';
import type { Methods as Methods_n2pqfv } from './v3/companies/settings/monthly_thanks';
import type { Methods as Methods_19sjlhi } from './v3/companies/settings/senders';
import type { Methods as Methods_1bn1jsz } from './v3/companies/settings/viewers';
import type { Methods as Methods_l1kgzx } from './v3/companies/settings/viewers/_id@number';
import type { Methods as Methods_1if0hoq } from './v3/company_counts';
import type { Methods as Methods_1pe4rsi } from './v3/company_services';
import type { Methods as Methods_11mo3bv } from './v3/company_services/link';
import type { Methods as Methods_mptnif } from './v3/csv_registration_download';
import type { Methods as Methods_ftm4ei } from './v3/csv_registration_import';
import type { Methods as Methods_f21kfh } from './v3/csv_registration_validate';
import type { Methods as Methods_3ovmqh } from './v3/csv_timeline_download';
import type { Methods as Methods_19gs8jg } from './v3/csv_timeline_upload';
import type { Methods as Methods_54dzza } from './v3/csv_update_import';
import type { Methods as Methods_18947vt } from './v3/csv_update_validate';
import type { Methods as Methods_13aznd9 } from './v3/engage/callback';
import type { Methods as Methods_1j11psk } from './v3/enq_mail_settings/_enqMsgType@number';
import type { Methods as Methods_cldied } from './v3/enq_mail_settings/preview/_enqMsgType@number';
import type { Methods as Methods_atkgz8 } from './v3/enq_mail_settings/save/_enqMsgType@number';
import type { Methods as Methods_vy3sbp } from './v3/enqs';
import type { Methods as Methods_acnjgd } from './v3/enqs/custom_monthly_questions';
import type { Methods as Methods_1wpqwnz } from './v3/enqs/custom_monthly_questions/_id@number';
import type { Methods as Methods_1rvtenj } from './v3/ip';
import type { Methods as Methods_1pv4vc7 } from './v3/line/auth/all';
import type { Methods as Methods_1rpqv2 } from './v3/line/auth/cancel';
import type { Methods as Methods_1vx2dx8 } from './v3/line/auth/personal';
import type { Methods as Methods_17snvn8 } from './v3/line/members';
import type { Methods as Methods_hqmsuz } from './v3/login';
import type { Methods as Methods_1eeieyw } from './v3/logout';
import type { Methods as Methods_1xpxbur } from './v3/mail_settings';
import type { Methods as Methods_tvt82o } from './v3/mail_settings/preview';
import type { Methods as Methods_15zwffl } from './v3/mail_settings/save';
import type { Methods as Methods_1dvws50 } from './v3/mailtests/annual-old';
import type { Methods as Methods_226gna } from './v3/mailtests/annual-remind';
import type { Methods as Methods_1vjsckc } from './v3/mailtests/annual';
import type { Methods as Methods_4v8awe } from './v3/mailtests/monthly-all';
import type { Methods as Methods_pfyxjs } from './v3/mailtests/monthly-complete';
import type { Methods as Methods_97axge } from './v3/mailtests/monthly-remind';
import type { Methods as Methods_2yvl9h } from './v3/mailtests/monthly-summary';
import type { Methods as Methods_19a28sk } from './v3/mailtests/monthly';
import type { Methods as Methods_11b4fbg } from './v3/mailtests/senior-report';
import type { Methods as Methods_1dwzfyu } from './v3/mailtests/thanks';
import type { Methods as Methods_headth } from './v3/mailtests/welcome';
import type { Methods as Methods_1tift75 } from './v3/members';
import type { Methods as Methods_xrabr7 } from './v3/members/_id@number';
import type { Methods as Methods_6eqyyo } from './v3/members/_id@number/anniv_answers';
import type { Methods as Methods_m8m075 } from './v3/members/_id@number/anniv_read';
import type { Methods as Methods_1eygz75 } from './v3/members/_id@number/mail_schedule';
import type { Methods as Methods_uoct5y } from './v3/members/_id@number/test_mail';
import type { Methods as Methods_1yvpf6b } from './v3/members/anniv_answers_list';
import type { Methods as Methods_vbknhs } from './v3/members/anniversaries/count';
import type { Methods as Methods_1o9t11g } from './v3/members/bounce_mail_count';
import type { Methods as Methods_1m12t7u } from './v3/members/download';
import type { Methods as Methods_xtk5m5 } from './v3/members/first_delivery';
import type { Methods as Methods_wtnd9n } from './v3/members/monthly';
import type { Methods as Methods_1gdyleq } from './v3/members/read_message/_id@number';
import type { Methods as Methods_9uxdm1 } from './v3/members/unread';
import type { Methods as Methods_1vfcyhm } from './v3/messages';
import type { Methods as Methods_2wrr6q } from './v3/messages/_id@string';
import type { Methods as Methods_b0t1g9 } from './v3/messages/master_templates';
import type { Methods as Methods_3m4y2z } from './v3/messages/master_templates/_code@string';
import type { Methods as Methods_1d2961 } from './v3/messages/send';
import type { Methods as Methods_ab4p2y } from './v3/messages/templates';
import type { Methods as Methods_78upfu } from './v3/messages/templates/_code@string';
import type { Methods as Methods_k87vgt } from './v3/notification_special';
import type { Methods as Methods_ikz3gi } from './v3/notifications';
import type { Methods as Methods_ksb4h7 } from './v3/notifications/_notificationId@number';
import type { Methods as Methods_16ynyar } from './v3/password_reset';
import type { Methods as Methods_1uixepf } from './v3/password_reset_execute';
import type { Methods as Methods_17bfp8d } from './v3/refresh-token';
import type { Methods as Methods_1yac0jn } from './v3/seniors';
import type { Methods as Methods_8c6eod } from './v3/seniors/_id@number';
import type { Methods as Methods_54y3ay } from './v3/settings/security';
import type { Methods as Methods_p6q8tf } from './v3/settings/security/categories';
import type { Methods as Methods_113hmtj } from './v3/settings/security/download_logs';
import type { Methods as Methods_16qg1hb } from './v3/settings/security/log';
import type { Methods as Methods_85l7fh } from './v3/settings/security/password_reset_email';
import type { Methods as Methods_vdfteq } from './v3/settings/security/register';
import type { Methods as Methods_1monmf8 } from './v3/slack/auth/cancel';
import type { Methods as Methods_1q0znzi } from './v3/slack/auth/personal';
import type { Methods as Methods_e1u58t } from './v3/slack/auth/workspace_clear';
import type { Methods as Methods_n6q1xq } from './v3/slack/members';
import type { Methods as Methods_nexi3m } from './v3/slack/team';
import type { Methods as Methods_w22q1a } from './v3/sms/auth/all';
import type { Methods as Methods_k124wd } from './v3/sms/auth/cancel';
import type { Methods as Methods_1qyh6wb } from './v3/sms/auth/personal';
import type { Methods as Methods_1652o2z } from './v3/sms/members';
import type { Methods as Methods_2v9wfp } from './v3/talent_analytics/csv_talent_import';
import type { Methods as Methods_1hjp22c } from './v3/talent_analytics/link';
import type { Methods as Methods_1xismq2 } from './v3/talent_analytics/pdf';
import type { Methods as Methods_1g3fxfk } from './v3/talent_analytics/relation';
import type { Methods as Methods_6z1624 } from './v3/talent_analytics/relation/_id@number';
import type { Methods as Methods_vhqsei } from './v3/talent_analytics/synchronize';
import type { Methods as Methods_8ykp2f } from './v3/talent_analytics/talent_link_members';
import type { Methods as Methods_r9gnqq } from './v3/talent_analytics/talent_members';
import type { Methods as Methods_6397s0 } from './v3/talent_analytics/talent_unlink_members';
import type { Methods as Methods_1pojdoc } from './v3/temp_members';
import type { Methods as Methods_1wmffq0 } from './v3/temp_members/_id@number';
import type { Methods as Methods_1xxmrqw } from './v3/template_contents/preview';
import type { Methods as Methods_11drflq } from './v4/auth/me';
import type { Methods as Methods_1ruep36 } from './v4/common/company/monthly-mail';
import type { Methods as Methods_10dowp2 } from './v4/common/department-managers';
import type { Methods as Methods_1jspef6 } from './v4/common/department-managers/_id@number';
import type { Methods as Methods_3btn8w } from './v4/common/departments';
import type { Methods as Methods_j6oyzg } from './v4/common/departments/_id@number';
import type { Methods as Methods_1ssvevw } from './v4/common/departments/_id@number/members';
import type { Methods as Methods_so7kot } from './v4/common/departments/archive';
import type { Methods as Methods_1t4i5y9 } from './v4/common/departments/search';
import type { Methods as Methods_1nvytny } from './v4/common/import-departments';
import type { Methods as Methods_u9ayjr } from './v4/common/import-departments/read';
import type { Methods as Methods_16ajw2e } from './v4/common/seniors';
import type { Methods as Methods_1p153f6 } from './v4/common/seniors/_id@number';
import type { Methods as Methods_1gql4f } from './v4/common/users';
import type { Methods as Methods_1aqek6x } from './v4/common/users/_id@number';
import type { Methods as Methods_r28q7b } from './v4/common/users/_id@number/image';
import type { Methods as Methods_13bf93j } from './v4/common/users/count';
import type { Methods as Methods_1ixckjl } from './v4/common/users/display-limits';
import type { Methods as Methods_1jhzvzb } from './v4/common/users/mail';
import type { Methods as Methods_1445it0 } from './v4/obf/emotion-answers/feedback/_id@number';
import type { Methods as Methods_qj61yu } from './v4/obf/members';
import type { Methods as Methods_nua34e } from './v4/obf/members/_code@string';
import type { Methods as Methods_12idmcy } from './v4/obf/members/_id@number';
import type { Methods as Methods_yjx02h } from './v4/obf/members/_id@number/talent-examination';
import type { Methods as Methods_7vjkrf } from './v4/obf/members/anniversary';
import type { Methods as Methods_derogy } from './v4/obf/members/message';
import type { Methods as Methods_qxgdgj } from './v4/obf/members/search';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '');
  const PATH0 = '/v3/actionlogs';
  const PATH1 = '/v3/anniversaries/report';
  const PATH2 = '/v3/anniversaries/xlsx-new';
  const PATH3 = '/v3/anniversaries/xlsx';
  const PATH4 = '/v3/answers/chart';
  const PATH5 = '/v3/answers/report';
  const PATH6 = '/v3/answers/report/calendar';
  const PATH7 = '/v3/answers/report/condition-calendar';
  const PATH8 = '/v3/answers/report/condition-count';
  const PATH9 = '/v3/answers/report/condition-detail';
  const PATH10 = '/v3/answers/report/count';
  const PATH11 = '/v3/answers/xlsx';
  const PATH12 = '/v3/avatars';
  const PATH13 = '/v3/companies';
  const PATH14 = '/v3/companies/annual_confirmation';
  const PATH15 = '/v3/companies/annual_notification';
  const PATH16 = '/v3/companies/pages';
  const PATH17 = '/v3/companies/settings/initial_mails';
  const PATH18 = '/v3/companies/settings/member_list_count';
  const PATH19 = '/v3/companies/settings/monthly_notifications';
  const PATH20 = '/v3/companies/settings/monthly_thanks';
  const PATH21 = '/v3/companies/settings/senders';
  const PATH22 = '/v3/companies/settings/viewers';
  const PATH23 = '/v3/company_counts';
  const PATH24 = '/v3/company_services';
  const PATH25 = '/v3/company_services/link';
  const PATH26 = '/v3/csv_registration_download';
  const PATH27 = '/v3/csv_registration_import';
  const PATH28 = '/v3/csv_registration_validate';
  const PATH29 = '/v3/csv_timeline_download';
  const PATH30 = '/v3/csv_timeline_upload';
  const PATH31 = '/v3/csv_update_import';
  const PATH32 = '/v3/csv_update_validate';
  const PATH33 = '/v3/engage/callback';
  const PATH34 = '/v3/enq_mail_settings';
  const PATH35 = '/v3/enq_mail_settings/preview';
  const PATH36 = '/v3/enq_mail_settings/save';
  const PATH37 = '/v3/enqs';
  const PATH38 = '/v3/enqs/custom_monthly_questions';
  const PATH39 = '/v3/ip';
  const PATH40 = '/v3/line/auth/all';
  const PATH41 = '/v3/line/auth/cancel';
  const PATH42 = '/v3/line/auth/personal';
  const PATH43 = '/v3/line/members';
  const PATH44 = '/v3/login';
  const PATH45 = '/v3/logout';
  const PATH46 = '/v3/mail_settings';
  const PATH47 = '/v3/mail_settings/preview';
  const PATH48 = '/v3/mail_settings/save';
  const PATH49 = '/v3/mailtests/annual-old';
  const PATH50 = '/v3/mailtests/annual-remind';
  const PATH51 = '/v3/mailtests/annual';
  const PATH52 = '/v3/mailtests/monthly-all';
  const PATH53 = '/v3/mailtests/monthly-complete';
  const PATH54 = '/v3/mailtests/monthly-remind';
  const PATH55 = '/v3/mailtests/monthly-summary';
  const PATH56 = '/v3/mailtests/monthly';
  const PATH57 = '/v3/mailtests/senior-report';
  const PATH58 = '/v3/mailtests/thanks';
  const PATH59 = '/v3/mailtests/welcome';
  const PATH60 = '/v3/members';
  const PATH61 = '/anniv_answers';
  const PATH62 = '/anniv_read';
  const PATH63 = '/mail_schedule';
  const PATH64 = '/test_mail';
  const PATH65 = '/v3/members/anniv_answers_list';
  const PATH66 = '/v3/members/anniversaries/count';
  const PATH67 = '/v3/members/bounce_mail_count';
  const PATH68 = '/v3/members/download';
  const PATH69 = '/v3/members/first_delivery';
  const PATH70 = '/v3/members/monthly';
  const PATH71 = '/v3/members/read_message';
  const PATH72 = '/v3/members/unread';
  const PATH73 = '/v3/messages';
  const PATH74 = '/v3/messages/master_templates';
  const PATH75 = '/v3/messages/send';
  const PATH76 = '/v3/messages/templates';
  const PATH77 = '/v3/notification_special';
  const PATH78 = '/v3/notifications';
  const PATH79 = '/v3/password_reset';
  const PATH80 = '/v3/password_reset_execute';
  const PATH81 = '/v3/refresh-token';
  const PATH82 = '/v3/seniors';
  const PATH83 = '/v3/settings/security';
  const PATH84 = '/v3/settings/security/categories';
  const PATH85 = '/v3/settings/security/download_logs';
  const PATH86 = '/v3/settings/security/log';
  const PATH87 = '/v3/settings/security/password_reset_email';
  const PATH88 = '/v3/settings/security/register';
  const PATH89 = '/v3/slack/auth/cancel';
  const PATH90 = '/v3/slack/auth/personal';
  const PATH91 = '/v3/slack/auth/workspace_clear';
  const PATH92 = '/v3/slack/members';
  const PATH93 = '/v3/slack/team';
  const PATH94 = '/v3/sms/auth/all';
  const PATH95 = '/v3/sms/auth/cancel';
  const PATH96 = '/v3/sms/auth/personal';
  const PATH97 = '/v3/sms/members';
  const PATH98 = '/v3/talent_analytics/csv_talent_import';
  const PATH99 = '/v3/talent_analytics/link';
  const PATH100 = '/v3/talent_analytics/pdf';
  const PATH101 = '/v3/talent_analytics/relation';
  const PATH102 = '/v3/talent_analytics/synchronize';
  const PATH103 = '/v3/talent_analytics/talent_link_members';
  const PATH104 = '/v3/talent_analytics/talent_members';
  const PATH105 = '/v3/talent_analytics/talent_unlink_members';
  const PATH106 = '/v3/temp_members';
  const PATH107 = '/v3/template_contents/preview';
  const PATH108 = '/v4/auth/me';
  const PATH109 = '/v4/common/company/monthly-mail';
  const PATH110 = '/v4/common/department-managers';
  const PATH111 = '/v4/common/departments';
  const PATH112 = '/members';
  const PATH113 = '/v4/common/departments/archive';
  const PATH114 = '/v4/common/departments/search';
  const PATH115 = '/v4/common/import-departments';
  const PATH116 = '/v4/common/import-departments/read';
  const PATH117 = '/v4/common/seniors';
  const PATH118 = '/v4/common/users';
  const PATH119 = '/image';
  const PATH120 = '/v4/common/users/count';
  const PATH121 = '/v4/common/users/display-limits';
  const PATH122 = '/v4/common/users/mail';
  const PATH123 = '/v4/obf/emotion-answers/feedback';
  const PATH124 = '/v4/obf/members';
  const PATH125 = '/talent-examination';
  const PATH126 = '/v4/obf/members/anniversary';
  const PATH127 = '/v4/obf/members/message';
  const PATH128 = '/v4/obf/members/search';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';
  const PATCH = 'PATCH';

  return {
    v3: {
      actionlogs: {
        _id: (val2: number) => {
          const prefix2 = `${PATH0}/${val2}`;

          return {
            put: (option: { body: Methods_d8jwkp['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_d8jwkp['put']['resBody']>(prefix, prefix2, PUT, option).json(),
            $put: (option: { body: Methods_d8jwkp['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_d8jwkp['put']['resBody']>(prefix, prefix2, PUT, option).json().then(r => r.body),
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_d8jwkp['delete']['resBody']>(prefix, prefix2, DELETE, option).json(),
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_d8jwkp['delete']['resBody']>(prefix, prefix2, DELETE, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        get: (option: { query: Methods_1gzr4nz['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1gzr4nz['get']['resBody']>(prefix, PATH0, GET, option).json(),
        $get: (option: { query: Methods_1gzr4nz['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1gzr4nz['get']['resBody']>(prefix, PATH0, GET, option).json().then(r => r.body),
        post: (option: { body: Methods_1gzr4nz['post']['reqBody'], query: Methods_1gzr4nz['post']['query'], config?: T | undefined }) =>
          fetch<Methods_1gzr4nz['post']['resBody']>(prefix, PATH0, POST, option).json(),
        $post: (option: { body: Methods_1gzr4nz['post']['reqBody'], query: Methods_1gzr4nz['post']['query'], config?: T | undefined }) =>
          fetch<Methods_1gzr4nz['post']['resBody']>(prefix, PATH0, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1gzr4nz['get']['query'] } | { method: 'post'; query: Methods_1gzr4nz['post']['query'] } | undefined) =>
          `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      anniversaries: {
        report: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_vc4hf7['get']['resBody']>(prefix, PATH1, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_vc4hf7['get']['resBody']>(prefix, PATH1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH1}`,
        },
        xlsx_new: {
          get: (option?: { query?: Methods_wp9j9l['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_wp9j9l['get']['resBody']>(prefix, PATH2, GET, option).blob(),
          $get: (option?: { query?: Methods_wp9j9l['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_wp9j9l['get']['resBody']>(prefix, PATH2, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_wp9j9l['get']['query'] } | undefined) =>
            `${prefix}${PATH2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        xlsx: {
          get: (option?: { query?: Methods_11c10bm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_11c10bm['get']['resBody']>(prefix, PATH3, GET, option).blob(),
          $get: (option?: { query?: Methods_11c10bm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_11c10bm['get']['resBody']>(prefix, PATH3, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_11c10bm['get']['query'] } | undefined) =>
            `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      answers: {
        chart: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1nbrtha['get']['resBody']>(prefix, PATH4, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1nbrtha['get']['resBody']>(prefix, PATH4, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH4}`,
        },
        report: {
          calendar: {
            get: (option: { query: Methods_hu56pj['get']['query'], config?: T | undefined }) =>
              fetch<Methods_hu56pj['get']['resBody']>(prefix, PATH6, GET, option).json(),
            $get: (option: { query: Methods_hu56pj['get']['query'], config?: T | undefined }) =>
              fetch<Methods_hu56pj['get']['resBody']>(prefix, PATH6, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_hu56pj['get']['query'] } | undefined) =>
              `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          condition_calendar: {
            get: (option: { query: Methods_kvrrej['get']['query'], config?: T | undefined }) =>
              fetch<Methods_kvrrej['get']['resBody']>(prefix, PATH7, GET, option).json(),
            $get: (option: { query: Methods_kvrrej['get']['query'], config?: T | undefined }) =>
              fetch<Methods_kvrrej['get']['resBody']>(prefix, PATH7, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_kvrrej['get']['query'] } | undefined) =>
              `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          condition_count: {
            get: (option: { query: Methods_9zpadk['get']['query'], config?: T | undefined }) =>
              fetch<Methods_9zpadk['get']['resBody']>(prefix, PATH8, GET, option).json(),
            $get: (option: { query: Methods_9zpadk['get']['query'], config?: T | undefined }) =>
              fetch<Methods_9zpadk['get']['resBody']>(prefix, PATH8, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_9zpadk['get']['query'] } | undefined) =>
              `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          condition_detail: {
            get: (option: { query: Methods_mbyt5s['get']['query'], config?: T | undefined }) =>
              fetch<Methods_mbyt5s['get']['resBody']>(prefix, PATH9, GET, option).json(),
            $get: (option: { query: Methods_mbyt5s['get']['query'], config?: T | undefined }) =>
              fetch<Methods_mbyt5s['get']['resBody']>(prefix, PATH9, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_mbyt5s['get']['query'] } | undefined) =>
              `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          count: {
            get: (option: { query: Methods_1cukf5o['get']['query'], config?: T | undefined }) =>
              fetch<Methods_1cukf5o['get']['resBody']>(prefix, PATH10, GET, option).json(),
            $get: (option: { query: Methods_1cukf5o['get']['query'], config?: T | undefined }) =>
              fetch<Methods_1cukf5o['get']['resBody']>(prefix, PATH10, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1cukf5o['get']['query'] } | undefined) =>
              `${prefix}${PATH10}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_10ff740['get']['resBody']>(prefix, PATH5, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_10ff740['get']['resBody']>(prefix, PATH5, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH5}`,
        },
        xlsx: {
          get: (option?: { query?: Methods_pg0d25['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_pg0d25['get']['resBody']>(prefix, PATH11, GET, option).blob(),
          $get: (option?: { query?: Methods_pg0d25['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_pg0d25['get']['resBody']>(prefix, PATH11, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_pg0d25['get']['query'] } | undefined) =>
            `${prefix}${PATH11}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      avatars: {
        _url: (val2: string) => {
          const prefix2 = `${PATH12}/${val2}`;

          return {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1ha0uec['get']['resBody']>(prefix, prefix2, GET, option).blob(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1ha0uec['get']['resBody']>(prefix, prefix2, GET, option).blob().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
      companies: {
        annual_confirmation: {
          patch: (option: { body: Methods_80nipv['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_80nipv['patch']['resBody']>(prefix, PATH14, PATCH, option).json(),
          $patch: (option: { body: Methods_80nipv['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_80nipv['patch']['resBody']>(prefix, PATH14, PATCH, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH14}`,
        },
        annual_notification: {
          patch: (option: { body: Methods_mlpqqx['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_mlpqqx['patch']['resBody']>(prefix, PATH15, PATCH, option).json(),
          $patch: (option: { body: Methods_mlpqqx['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_mlpqqx['patch']['resBody']>(prefix, PATH15, PATCH, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH15}`,
        },
        pages: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1ohkhcu['get']['resBody']>(prefix, PATH16, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1ohkhcu['get']['resBody']>(prefix, PATH16, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH16}`,
        },
        settings: {
          initial_mails: {
            patch: (option: { body: Methods_7js5tf['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_7js5tf['patch']['resBody']>(prefix, PATH17, PATCH, option).json(),
            $patch: (option: { body: Methods_7js5tf['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_7js5tf['patch']['resBody']>(prefix, PATH17, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH17}`,
          },
          member_list_count: {
            patch: (option: { body: Methods_1rlba1z['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1rlba1z['patch']['resBody']>(prefix, PATH18, PATCH, option).json(),
            $patch: (option: { body: Methods_1rlba1z['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1rlba1z['patch']['resBody']>(prefix, PATH18, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH18}`,
          },
          monthly_notifications: {
            patch: (option: { body: Methods_5252iq['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_5252iq['patch']['resBody']>(prefix, PATH19, PATCH, option).json(),
            $patch: (option: { body: Methods_5252iq['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_5252iq['patch']['resBody']>(prefix, PATH19, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH19}`,
          },
          monthly_thanks: {
            patch: (option: { body: Methods_n2pqfv['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_n2pqfv['patch']['resBody']>(prefix, PATH20, PATCH, option).json(),
            $patch: (option: { body: Methods_n2pqfv['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_n2pqfv['patch']['resBody']>(prefix, PATH20, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH20}`,
          },
          senders: {
            patch: (option: { body: Methods_19sjlhi['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_19sjlhi['patch']['resBody']>(prefix, PATH21, PATCH, option).json(),
            $patch: (option: { body: Methods_19sjlhi['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_19sjlhi['patch']['resBody']>(prefix, PATH21, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH21}`,
          },
          viewers: {
            _id: (val4: number) => {
              const prefix4 = `${PATH22}/${val4}`;

              return {
                put: (option: { body: Methods_l1kgzx['put']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_l1kgzx['put']['resBody']>(prefix, prefix4, PUT, option).json(),
                $put: (option: { body: Methods_l1kgzx['put']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_l1kgzx['put']['resBody']>(prefix, prefix4, PUT, option).json().then(r => r.body),
                delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_l1kgzx['delete']['resBody']>(prefix, prefix4, DELETE, option).json(),
                $delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_l1kgzx['delete']['resBody']>(prefix, prefix4, DELETE, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1bn1jsz['get']['resBody']>(prefix, PATH22, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1bn1jsz['get']['resBody']>(prefix, PATH22, GET, option).json().then(r => r.body),
            post: (option: { body: Methods_1bn1jsz['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1bn1jsz['post']['resBody']>(prefix, PATH22, POST, option).json(),
            $post: (option: { body: Methods_1bn1jsz['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1bn1jsz['post']['resBody']>(prefix, PATH22, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH22}`,
          },
        },
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_vu5m95['get']['resBody']>(prefix, PATH13, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_vu5m95['get']['resBody']>(prefix, PATH13, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH13}`,
      },
      company_counts: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1if0hoq['get']['resBody']>(prefix, PATH23, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1if0hoq['get']['resBody']>(prefix, PATH23, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH23}`,
      },
      company_services: {
        link: {
          patch: (option: { body: Methods_11mo3bv['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_11mo3bv['patch']['resBody']>(prefix, PATH25, PATCH, option).json(),
          $patch: (option: { body: Methods_11mo3bv['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_11mo3bv['patch']['resBody']>(prefix, PATH25, PATCH, option).json().then(r => r.body),
          delete: (option: { query: Methods_11mo3bv['delete']['query'], config?: T | undefined }) =>
            fetch<Methods_11mo3bv['delete']['resBody']>(prefix, PATH25, DELETE, option).json(),
          $delete: (option: { query: Methods_11mo3bv['delete']['query'], config?: T | undefined }) =>
            fetch<Methods_11mo3bv['delete']['resBody']>(prefix, PATH25, DELETE, option).json().then(r => r.body),
          $path: (option?: { method: 'delete'; query: Methods_11mo3bv['delete']['query'] } | undefined) =>
            `${prefix}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1pe4rsi['get']['resBody']>(prefix, PATH24, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1pe4rsi['get']['resBody']>(prefix, PATH24, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH24}`,
      },
      csv_registration_download: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_mptnif['get']['resBody']>(prefix, PATH26, GET, option).blob(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_mptnif['get']['resBody']>(prefix, PATH26, GET, option).blob().then(r => r.body),
        $path: () => `${prefix}${PATH26}`,
      },
      csv_registration_import: {
        post: (option: { body: Methods_ftm4ei['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_ftm4ei['post']['resBody']>(prefix, PATH27, POST, option, 'FormData').json(),
        $post: (option: { body: Methods_ftm4ei['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_ftm4ei['post']['resBody']>(prefix, PATH27, POST, option, 'FormData').json().then(r => r.body),
        $path: () => `${prefix}${PATH27}`,
      },
      csv_registration_validate: {
        post: (option: { body: Methods_f21kfh['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_f21kfh['post']['resBody']>(prefix, PATH28, POST, option, 'FormData').json(),
        $post: (option: { body: Methods_f21kfh['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_f21kfh['post']['resBody']>(prefix, PATH28, POST, option, 'FormData').json().then(r => r.body),
        $path: () => `${prefix}${PATH28}`,
      },
      csv_timeline_download: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_3ovmqh['get']['resBody']>(prefix, PATH29, GET, option).blob(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_3ovmqh['get']['resBody']>(prefix, PATH29, GET, option).blob().then(r => r.body),
        $path: () => `${prefix}${PATH29}`,
      },
      csv_timeline_upload: {
        post: (option: { body: Methods_19gs8jg['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_19gs8jg['post']['resBody']>(prefix, PATH30, POST, option, 'FormData').json(),
        $post: (option: { body: Methods_19gs8jg['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_19gs8jg['post']['resBody']>(prefix, PATH30, POST, option, 'FormData').json().then(r => r.body),
        $path: () => `${prefix}${PATH30}`,
      },
      csv_update_import: {
        post: (option: { body: Methods_54dzza['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_54dzza['post']['resBody']>(prefix, PATH31, POST, option, 'FormData').json(),
        $post: (option: { body: Methods_54dzza['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_54dzza['post']['resBody']>(prefix, PATH31, POST, option, 'FormData').json().then(r => r.body),
        $path: () => `${prefix}${PATH31}`,
      },
      csv_update_validate: {
        post: (option: { body: Methods_18947vt['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_18947vt['post']['resBody']>(prefix, PATH32, POST, option, 'FormData').json(),
        $post: (option: { body: Methods_18947vt['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_18947vt['post']['resBody']>(prefix, PATH32, POST, option, 'FormData').json().then(r => r.body),
        $path: () => `${prefix}${PATH32}`,
      },
      engage: {
        callback: {
          post: (option: { body: Methods_13aznd9['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_13aznd9['post']['resBody']>(prefix, PATH33, POST, option).json(),
          $post: (option: { body: Methods_13aznd9['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_13aznd9['post']['resBody']>(prefix, PATH33, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH33}`,
        },
      },
      enq_mail_settings: {
        _enqMsgType: (val2: number) => {
          const prefix2 = `${PATH34}/${val2}`;

          return {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1j11psk['get']['resBody']>(prefix, prefix2, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1j11psk['get']['resBody']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        preview: {
          _enqMsgType: (val3: number) => {
            const prefix3 = `${PATH35}/${val3}`;

            return {
              post: (option: { body: Methods_cldied['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_cldied['post']['resBody']>(prefix, prefix3, POST, option).json(),
              $post: (option: { body: Methods_cldied['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_cldied['post']['resBody']>(prefix, prefix3, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
        },
        save: {
          _enqMsgType: (val3: number) => {
            const prefix3 = `${PATH36}/${val3}`;

            return {
              patch: (option: { body: Methods_atkgz8['patch']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_atkgz8['patch']['resBody']>(prefix, prefix3, PATCH, option).json(),
              $patch: (option: { body: Methods_atkgz8['patch']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_atkgz8['patch']['resBody']>(prefix, prefix3, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
        },
      },
      enqs: {
        custom_monthly_questions: {
          _id: (val3: number) => {
            const prefix3 = `${PATH38}/${val3}`;

            return {
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1wpqwnz['delete']['resBody']>(prefix, prefix3, DELETE, option).json(),
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1wpqwnz['delete']['resBody']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              put: (option: { body: Methods_1wpqwnz['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1wpqwnz['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option: { body: Methods_1wpqwnz['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1wpqwnz['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              post: (option: { body: Methods_1wpqwnz['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1wpqwnz['post']['resBody']>(prefix, prefix3, POST, option).json(),
              $post: (option: { body: Methods_1wpqwnz['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1wpqwnz['post']['resBody']>(prefix, prefix3, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_acnjgd['get']['resBody']>(prefix, PATH38, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_acnjgd['get']['resBody']>(prefix, PATH38, GET, option).json().then(r => r.body),
          post: (option: { body: Methods_acnjgd['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_acnjgd['post']['resBody']>(prefix, PATH38, POST, option).json(),
          $post: (option: { body: Methods_acnjgd['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_acnjgd['post']['resBody']>(prefix, PATH38, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH38}`,
        },
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_vy3sbp['get']['resBody']>(prefix, PATH37, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_vy3sbp['get']['resBody']>(prefix, PATH37, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH37}`,
      },
      ip: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1rvtenj['get']['resBody']>(prefix, PATH39, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1rvtenj['get']['resBody']>(prefix, PATH39, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH39}`,
      },
      line: {
        auth: {
          all: {
            post: (option: { body: Methods_1pv4vc7['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1pv4vc7['post']['resBody']>(prefix, PATH40, POST, option).json(),
            $post: (option: { body: Methods_1pv4vc7['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1pv4vc7['post']['resBody']>(prefix, PATH40, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH40}`,
          },
          cancel: {
            patch: (option: { body: Methods_1rpqv2['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1rpqv2['patch']['resBody']>(prefix, PATH41, PATCH, option).json(),
            $patch: (option: { body: Methods_1rpqv2['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1rpqv2['patch']['resBody']>(prefix, PATH41, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH41}`,
          },
          personal: {
            post: (option: { body: Methods_1vx2dx8['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1vx2dx8['post']['resBody']>(prefix, PATH42, POST, option).json(),
            $post: (option: { body: Methods_1vx2dx8['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1vx2dx8['post']['resBody']>(prefix, PATH42, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH42}`,
          },
        },
        members: {
          get: (option: { query: Methods_17snvn8['get']['query'], config?: T | undefined }) =>
            fetch<Methods_17snvn8['get']['resBody']>(prefix, PATH43, GET, option).json(),
          $get: (option: { query: Methods_17snvn8['get']['query'], config?: T | undefined }) =>
            fetch<Methods_17snvn8['get']['resBody']>(prefix, PATH43, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_17snvn8['get']['query'] } | undefined) =>
            `${prefix}${PATH43}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      login: {
        post: (option: { body: Methods_hqmsuz['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_hqmsuz['post']['resBody']>(prefix, PATH44, POST, option).json(),
        $post: (option: { body: Methods_hqmsuz['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_hqmsuz['post']['resBody']>(prefix, PATH44, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH44}`,
      },
      logout: {
        delete: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1eeieyw['delete']['resBody']>(prefix, PATH45, DELETE, option).json(),
        $delete: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1eeieyw['delete']['resBody']>(prefix, PATH45, DELETE, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH45}`,
      },
      mail_settings: {
        preview: {
          post: (option: { body: Methods_tvt82o['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_tvt82o['post']['resBody']>(prefix, PATH47, POST, option).json(),
          $post: (option: { body: Methods_tvt82o['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_tvt82o['post']['resBody']>(prefix, PATH47, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH47}`,
        },
        save: {
          patch: (option: { body: Methods_15zwffl['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_15zwffl['patch']['resBody']>(prefix, PATH48, PATCH, option).json(),
          $patch: (option: { body: Methods_15zwffl['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_15zwffl['patch']['resBody']>(prefix, PATH48, PATCH, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH48}`,
        },
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1xpxbur['get']['resBody']>(prefix, PATH46, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1xpxbur['get']['resBody']>(prefix, PATH46, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH46}`,
      },
      mailtests: {
        annual_old: {
          post: (option: { body: Methods_1dvws50['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1dvws50['post']['resBody']>(prefix, PATH49, POST, option).json(),
          $post: (option: { body: Methods_1dvws50['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1dvws50['post']['resBody']>(prefix, PATH49, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH49}`,
        },
        annual_remind: {
          post: (option: { body: Methods_226gna['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_226gna['post']['resBody']>(prefix, PATH50, POST, option).json(),
          $post: (option: { body: Methods_226gna['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_226gna['post']['resBody']>(prefix, PATH50, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH50}`,
        },
        annual: {
          post: (option: { body: Methods_1vjsckc['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1vjsckc['post']['resBody']>(prefix, PATH51, POST, option).json(),
          $post: (option: { body: Methods_1vjsckc['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1vjsckc['post']['resBody']>(prefix, PATH51, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH51}`,
        },
        monthly_all: {
          post: (option: { body: Methods_4v8awe['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_4v8awe['post']['resBody']>(prefix, PATH52, POST, option).json(),
          $post: (option: { body: Methods_4v8awe['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_4v8awe['post']['resBody']>(prefix, PATH52, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH52}`,
        },
        monthly_complete: {
          post: (option: { body: Methods_pfyxjs['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_pfyxjs['post']['resBody']>(prefix, PATH53, POST, option).json(),
          $post: (option: { body: Methods_pfyxjs['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_pfyxjs['post']['resBody']>(prefix, PATH53, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH53}`,
        },
        monthly_remind: {
          post: (option: { body: Methods_97axge['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_97axge['post']['resBody']>(prefix, PATH54, POST, option).json(),
          $post: (option: { body: Methods_97axge['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_97axge['post']['resBody']>(prefix, PATH54, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH54}`,
        },
        monthly_summary: {
          post: (option: { body: Methods_2yvl9h['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_2yvl9h['post']['resBody']>(prefix, PATH55, POST, option).json(),
          $post: (option: { body: Methods_2yvl9h['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_2yvl9h['post']['resBody']>(prefix, PATH55, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH55}`,
        },
        monthly: {
          post: (option: { body: Methods_19a28sk['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_19a28sk['post']['resBody']>(prefix, PATH56, POST, option).json(),
          $post: (option: { body: Methods_19a28sk['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_19a28sk['post']['resBody']>(prefix, PATH56, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH56}`,
        },
        senior_report: {
          post: (option: { body: Methods_11b4fbg['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_11b4fbg['post']['resBody']>(prefix, PATH57, POST, option).json(),
          $post: (option: { body: Methods_11b4fbg['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_11b4fbg['post']['resBody']>(prefix, PATH57, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH57}`,
        },
        thanks: {
          post: (option: { body: Methods_1dwzfyu['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1dwzfyu['post']['resBody']>(prefix, PATH58, POST, option).json(),
          $post: (option: { body: Methods_1dwzfyu['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1dwzfyu['post']['resBody']>(prefix, PATH58, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH58}`,
        },
        welcome: {
          post: (option: { body: Methods_headth['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_headth['post']['resBody']>(prefix, PATH59, POST, option).json(),
          $post: (option: { body: Methods_headth['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_headth['post']['resBody']>(prefix, PATH59, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH59}`,
        },
      },
      members: {
        _id: (val2: number) => {
          const prefix2 = `${PATH60}/${val2}`;

          return {
            anniv_answers: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_6eqyyo['get']['resBody']>(prefix, `${prefix2}${PATH61}`, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_6eqyyo['get']['resBody']>(prefix, `${prefix2}${PATH61}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH61}`,
            },
            anniv_read: {
              put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_m8m075['put']['resBody']>(prefix, `${prefix2}${PATH62}`, PUT, option).text(),
              $put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_m8m075['put']['resBody']>(prefix, `${prefix2}${PATH62}`, PUT, option).text().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH62}`,
            },
            mail_schedule: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1eygz75['get']['resBody']>(prefix, `${prefix2}${PATH63}`, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1eygz75['get']['resBody']>(prefix, `${prefix2}${PATH63}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH63}`,
            },
            test_mail: {
              post: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_uoct5y['post']['resBody']>(prefix, `${prefix2}${PATH64}`, POST, option).json(),
              $post: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_uoct5y['post']['resBody']>(prefix, `${prefix2}${PATH64}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH64}`,
            },
            patch: (option: { body: Methods_xrabr7['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_xrabr7['patch']['resBody']>(prefix, prefix2, PATCH, option).json(),
            $patch: (option: { body: Methods_xrabr7['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_xrabr7['patch']['resBody']>(prefix, prefix2, PATCH, option).json().then(r => r.body),
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch(prefix, prefix2, DELETE, option).send(),
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        anniv_answers_list: {
          get: (option?: { query?: Methods_1yvpf6b['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1yvpf6b['get']['resBody']>(prefix, PATH65, GET, option).json(),
          $get: (option?: { query?: Methods_1yvpf6b['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1yvpf6b['get']['resBody']>(prefix, PATH65, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1yvpf6b['get']['query'] } | undefined) =>
            `${prefix}${PATH65}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        anniversaries: {
          count: {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_vbknhs['get']['resBody']>(prefix, PATH66, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_vbknhs['get']['resBody']>(prefix, PATH66, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH66}`,
          },
        },
        bounce_mail_count: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1o9t11g['get']['resBody']>(prefix, PATH67, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1o9t11g['get']['resBody']>(prefix, PATH67, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH67}`,
        },
        download: {
          get: (option: { query: Methods_1m12t7u['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1m12t7u['get']['resBody']>(prefix, PATH68, GET, option).blob(),
          $get: (option: { query: Methods_1m12t7u['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1m12t7u['get']['resBody']>(prefix, PATH68, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1m12t7u['get']['query'] } | undefined) =>
            `${prefix}${PATH68}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        first_delivery: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_xtk5m5['get']['resBody']>(prefix, PATH69, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_xtk5m5['get']['resBody']>(prefix, PATH69, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH69}`,
        },
        monthly: {
          get: (option: { query: Methods_wtnd9n['get']['query'], config?: T | undefined }) =>
            fetch<Methods_wtnd9n['get']['resBody']>(prefix, PATH70, GET, option).json(),
          $get: (option: { query: Methods_wtnd9n['get']['query'], config?: T | undefined }) =>
            fetch<Methods_wtnd9n['get']['resBody']>(prefix, PATH70, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_wtnd9n['get']['query'] } | undefined) =>
            `${prefix}${PATH70}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        read_message: {
          _id: (val3: number) => {
            const prefix3 = `${PATH71}/${val3}`;

            return {
              put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1gdyleq['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1gdyleq['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
        },
        unread: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_9uxdm1['get']['resBody']>(prefix, PATH72, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_9uxdm1['get']['resBody']>(prefix, PATH72, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH72}`,
        },
        get: (option: { query: Methods_1tift75['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1tift75['get']['resBody']>(prefix, PATH60, GET, option).json(),
        $get: (option: { query: Methods_1tift75['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1tift75['get']['resBody']>(prefix, PATH60, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1tift75['get']['query'] } | undefined) =>
          `${prefix}${PATH60}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      messages: {
        _id: (val2: string) => {
          const prefix2 = `${PATH73}/${val2}`;

          return {
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_2wrr6q['delete']['resBody']>(prefix, prefix2, DELETE, option).text(),
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_2wrr6q['delete']['resBody']>(prefix, prefix2, DELETE, option).text().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        master_templates: {
          _code: (val3: string) => {
            const prefix3 = `${PATH74}/${val3}`;

            return {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_3m4y2z['get']['resBody']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_3m4y2z['get']['resBody']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_b0t1g9['get']['resBody']>(prefix, PATH74, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_b0t1g9['get']['resBody']>(prefix, PATH74, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH74}`,
        },
        send: {
          post: (option: { body: Methods_1d2961['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1d2961['post']['resBody']>(prefix, PATH75, POST, option).json(),
          $post: (option: { body: Methods_1d2961['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1d2961['post']['resBody']>(prefix, PATH75, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH75}`,
        },
        templates: {
          _code: (val3: string) => {
            const prefix3 = `${PATH76}/${val3}`;

            return {
              put: (option: { body: Methods_78upfu['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_78upfu['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option: { body: Methods_78upfu['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_78upfu['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_78upfu['delete']['resBody']>(prefix, prefix3, DELETE, option).json(),
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_78upfu['delete']['resBody']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_78upfu['get']['resBody']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_78upfu['get']['resBody']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_ab4p2y['get']['resBody']>(prefix, PATH76, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_ab4p2y['get']['resBody']>(prefix, PATH76, GET, option).json().then(r => r.body),
          post: (option: { body: Methods_ab4p2y['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_ab4p2y['post']['resBody']>(prefix, PATH76, POST, option).json(),
          $post: (option: { body: Methods_ab4p2y['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_ab4p2y['post']['resBody']>(prefix, PATH76, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH76}`,
        },
        get: (option?: { query?: Methods_1vfcyhm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1vfcyhm['get']['resBody']>(prefix, PATH73, GET, option).json(),
        $get: (option?: { query?: Methods_1vfcyhm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1vfcyhm['get']['resBody']>(prefix, PATH73, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1vfcyhm['get']['query'] } | undefined) =>
          `${prefix}${PATH73}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      notification_special: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_k87vgt['get']['resBody']>(prefix, PATH77, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_k87vgt['get']['resBody']>(prefix, PATH77, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH77}`,
      },
      notifications: {
        _notificationId: (val2: number) => {
          const prefix2 = `${PATH78}/${val2}`;

          return {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_ksb4h7['get']['resBody']>(prefix, prefix2, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_ksb4h7['get']['resBody']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        get: (option: { query: Methods_ikz3gi['get']['query'], config?: T | undefined }) =>
          fetch<Methods_ikz3gi['get']['resBody']>(prefix, PATH78, GET, option).json(),
        $get: (option: { query: Methods_ikz3gi['get']['query'], config?: T | undefined }) =>
          fetch<Methods_ikz3gi['get']['resBody']>(prefix, PATH78, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_ikz3gi['get']['query'] } | undefined) =>
          `${prefix}${PATH78}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      password_reset: {
        post: (option: { body: Methods_16ynyar['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_16ynyar['post']['resBody']>(prefix, PATH79, POST, option).json(),
        $post: (option: { body: Methods_16ynyar['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_16ynyar['post']['resBody']>(prefix, PATH79, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH79}`,
      },
      password_reset_execute: {
        post: (option: { body: Methods_1uixepf['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1uixepf['post']['resBody']>(prefix, PATH80, POST, option).json(),
        $post: (option: { body: Methods_1uixepf['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1uixepf['post']['resBody']>(prefix, PATH80, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH80}`,
      },
      refresh_token: {
        post: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_17bfp8d['post']['resBody']>(prefix, PATH81, POST, option).json(),
        $post: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_17bfp8d['post']['resBody']>(prefix, PATH81, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH81}`,
      },
      seniors: {
        _id: (val2: number) => {
          const prefix2 = `${PATH82}/${val2}`;

          return {
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_8c6eod['delete']['resBody']>(prefix, prefix2, DELETE, option).json(),
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_8c6eod['delete']['resBody']>(prefix, prefix2, DELETE, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1yac0jn['get']['resBody']>(prefix, PATH82, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1yac0jn['get']['resBody']>(prefix, PATH82, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH82}`,
      },
      settings: {
        security: {
          categories: {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_p6q8tf['get']['resBody']>(prefix, PATH84, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_p6q8tf['get']['resBody']>(prefix, PATH84, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH84}`,
          },
          download_logs: {
            get: (option: { query: Methods_113hmtj['get']['query'], config?: T | undefined }) =>
              fetch<Methods_113hmtj['get']['resBody']>(prefix, PATH85, GET, option).blob(),
            $get: (option: { query: Methods_113hmtj['get']['query'], config?: T | undefined }) =>
              fetch<Methods_113hmtj['get']['resBody']>(prefix, PATH85, GET, option).blob().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_113hmtj['get']['query'] } | undefined) =>
              `${prefix}${PATH85}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          log: {
            get: (option: { query: Methods_16qg1hb['get']['query'], config?: T | undefined }) =>
              fetch<Methods_16qg1hb['get']['resBody']>(prefix, PATH86, GET, option).json(),
            $get: (option: { query: Methods_16qg1hb['get']['query'], config?: T | undefined }) =>
              fetch<Methods_16qg1hb['get']['resBody']>(prefix, PATH86, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_16qg1hb['get']['query'] } | undefined) =>
              `${prefix}${PATH86}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          password_reset_email: {
            post: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_85l7fh['post']['resBody']>(prefix, PATH87, POST, option).json(),
            $post: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_85l7fh['post']['resBody']>(prefix, PATH87, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH87}`,
          },
          register: {
            post: (option: { body: Methods_vdfteq['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_vdfteq['post']['resBody']>(prefix, PATH88, POST, option).json(),
            $post: (option: { body: Methods_vdfteq['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_vdfteq['post']['resBody']>(prefix, PATH88, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH88}`,
          },
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_54y3ay['get']['resBody']>(prefix, PATH83, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_54y3ay['get']['resBody']>(prefix, PATH83, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods_54y3ay['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_54y3ay['patch']['resBody']>(prefix, PATH83, PATCH, option).json(),
          $patch: (option: { body: Methods_54y3ay['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_54y3ay['patch']['resBody']>(prefix, PATH83, PATCH, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH83}`,
        },
      },
      slack: {
        auth: {
          cancel: {
            patch: (option: { body: Methods_1monmf8['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1monmf8['patch']['resBody']>(prefix, PATH89, PATCH, option).json(),
            $patch: (option: { body: Methods_1monmf8['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1monmf8['patch']['resBody']>(prefix, PATH89, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH89}`,
          },
          personal: {
            patch: (option: { body: Methods_1q0znzi['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1q0znzi['patch']['resBody']>(prefix, PATH90, PATCH, option).json(),
            $patch: (option: { body: Methods_1q0znzi['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1q0znzi['patch']['resBody']>(prefix, PATH90, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH90}`,
          },
          workspace_clear: {
            patch: (option: { body: Methods_e1u58t['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_e1u58t['patch']['resBody']>(prefix, PATH91, PATCH, option).json(),
            $patch: (option: { body: Methods_e1u58t['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_e1u58t['patch']['resBody']>(prefix, PATH91, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH91}`,
          },
        },
        members: {
          get: (option: { query: Methods_n6q1xq['get']['query'], config?: T | undefined }) =>
            fetch<Methods_n6q1xq['get']['resBody']>(prefix, PATH92, GET, option).json(),
          $get: (option: { query: Methods_n6q1xq['get']['query'], config?: T | undefined }) =>
            fetch<Methods_n6q1xq['get']['resBody']>(prefix, PATH92, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_n6q1xq['get']['query'] } | undefined) =>
            `${prefix}${PATH92}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        team: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_nexi3m['get']['resBody']>(prefix, PATH93, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_nexi3m['get']['resBody']>(prefix, PATH93, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH93}`,
        },
      },
      sms: {
        auth: {
          all: {
            post: (option: { body: Methods_w22q1a['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_w22q1a['post']['resBody']>(prefix, PATH94, POST, option).json(),
            $post: (option: { body: Methods_w22q1a['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_w22q1a['post']['resBody']>(prefix, PATH94, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH94}`,
          },
          cancel: {
            patch: (option: { body: Methods_k124wd['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_k124wd['patch']['resBody']>(prefix, PATH95, PATCH, option).json(),
            $patch: (option: { body: Methods_k124wd['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_k124wd['patch']['resBody']>(prefix, PATH95, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH95}`,
          },
          personal: {
            post: (option: { body: Methods_1qyh6wb['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1qyh6wb['post']['resBody']>(prefix, PATH96, POST, option).json(),
            $post: (option: { body: Methods_1qyh6wb['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1qyh6wb['post']['resBody']>(prefix, PATH96, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH96}`,
          },
        },
        members: {
          get: (option: { query: Methods_1652o2z['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1652o2z['get']['resBody']>(prefix, PATH97, GET, option).json(),
          $get: (option: { query: Methods_1652o2z['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1652o2z['get']['resBody']>(prefix, PATH97, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1652o2z['get']['query'] } | undefined) =>
            `${prefix}${PATH97}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      talent_analytics: {
        csv_talent_import: {
          post: (option: { body: Methods_2v9wfp['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_2v9wfp['post']['resBody']>(prefix, PATH98, POST, option, 'FormData').json(),
          $post: (option: { body: Methods_2v9wfp['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_2v9wfp['post']['resBody']>(prefix, PATH98, POST, option, 'FormData').json().then(r => r.body),
          $path: () => `${prefix}${PATH98}`,
        },
        link: {
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1hjp22c['delete']['resBody']>(prefix, PATH99, DELETE, option).json(),
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1hjp22c['delete']['resBody']>(prefix, PATH99, DELETE, option).json().then(r => r.body),
          patch: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1hjp22c['patch']['resBody']>(prefix, PATH99, PATCH, option).json(),
          $patch: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1hjp22c['patch']['resBody']>(prefix, PATH99, PATCH, option).json().then(r => r.body),
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1hjp22c['get']['resBody']>(prefix, PATH99, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1hjp22c['get']['resBody']>(prefix, PATH99, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH99}`,
        },
        pdf: {
          post: (option: { body: Methods_1xismq2['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1xismq2['post']['resBody']>(prefix, PATH100, POST, option).json(),
          $post: (option: { body: Methods_1xismq2['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1xismq2['post']['resBody']>(prefix, PATH100, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH100}`,
        },
        relation: {
          _id: (val3: number) => {
            const prefix3 = `${PATH101}/${val3}`;

            return {
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_6z1624['delete']['resBody']>(prefix, prefix3, DELETE, option).json(),
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_6z1624['delete']['resBody']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          patch: (option: { body: Methods_1g3fxfk['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1g3fxfk['patch']['resBody']>(prefix, PATH101, PATCH, option).json(),
          $patch: (option: { body: Methods_1g3fxfk['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1g3fxfk['patch']['resBody']>(prefix, PATH101, PATCH, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH101}`,
        },
        synchronize: {
          put: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_vhqsei['put']['resBody']>(prefix, PATH102, PUT, option).json(),
          $put: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_vhqsei['put']['resBody']>(prefix, PATH102, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH102}`,
        },
        talent_link_members: {
          get: (option?: { query?: Methods_8ykp2f['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_8ykp2f['get']['resBody']>(prefix, PATH103, GET, option).json(),
          $get: (option?: { query?: Methods_8ykp2f['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_8ykp2f['get']['resBody']>(prefix, PATH103, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_8ykp2f['get']['query'] } | undefined) =>
            `${prefix}${PATH103}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        talent_members: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_r9gnqq['get']['resBody']>(prefix, PATH104, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_r9gnqq['get']['resBody']>(prefix, PATH104, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH104}`,
        },
        talent_unlink_members: {
          get: (option?: { query?: Methods_6397s0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_6397s0['get']['resBody']>(prefix, PATH105, GET, option).json(),
          $get: (option?: { query?: Methods_6397s0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_6397s0['get']['resBody']>(prefix, PATH105, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_6397s0['get']['query'] } | undefined) =>
            `${prefix}${PATH105}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      temp_members: {
        _id: (val2: number) => {
          const prefix2 = `${PATH106}/${val2}`;

          return {
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1wmffq0['delete']['resBody']>(prefix, prefix2, DELETE, option).json(),
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1wmffq0['delete']['resBody']>(prefix, prefix2, DELETE, option).json().then(r => r.body),
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1wmffq0['get']['resBody']>(prefix, prefix2, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1wmffq0['get']['resBody']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1pojdoc['get']['resBody']>(prefix, PATH106, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1pojdoc['get']['resBody']>(prefix, PATH106, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH106}`,
      },
      template_contents: {
        preview: {
          post: (option: { body: Methods_1xxmrqw['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1xxmrqw['post']['resBody']>(prefix, PATH107, POST, option).json(),
          $post: (option: { body: Methods_1xxmrqw['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1xxmrqw['post']['resBody']>(prefix, PATH107, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH107}`,
        },
      },
    },
    v4: {
      auth: {
        me: {
          get: (option?: { query?: Methods_11drflq['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_11drflq['get']['resBody']>(prefix, PATH108, GET, option).json(),
          $get: (option?: { query?: Methods_11drflq['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_11drflq['get']['resBody']>(prefix, PATH108, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_11drflq['get']['query'] } | undefined) =>
            `${prefix}${PATH108}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      common: {
        company: {
          monthly_mail: {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1ruep36['get']['resBody']>(prefix, PATH109, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1ruep36['get']['resBody']>(prefix, PATH109, GET, option).json().then(r => r.body),
            put: (option: { body: Methods_1ruep36['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1ruep36['put']['resBody']>(prefix, PATH109, PUT, option).json(),
            $put: (option: { body: Methods_1ruep36['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1ruep36['put']['resBody']>(prefix, PATH109, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH109}`,
          },
        },
        department_managers: {
          _id: (val3: number) => {
            const prefix3 = `${PATH110}/${val3}`;

            return {
              put: (option: { body: Methods_1jspef6['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1jspef6['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option: { body: Methods_1jspef6['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1jspef6['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1jspef6['delete']['resBody']>(prefix, prefix3, DELETE, option).json(),
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1jspef6['delete']['resBody']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_10dowp2['get']['resBody']>(prefix, PATH110, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_10dowp2['get']['resBody']>(prefix, PATH110, GET, option).json().then(r => r.body),
          post: (option: { body: Methods_10dowp2['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_10dowp2['post']['resBody']>(prefix, PATH110, POST, option).json(),
          $post: (option: { body: Methods_10dowp2['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_10dowp2['post']['resBody']>(prefix, PATH110, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH110}`,
        },
        departments: {
          _id: (val3: number) => {
            const prefix3 = `${PATH111}/${val3}`;

            return {
              members: {
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1ssvevw['get']['resBody']>(prefix, `${prefix3}${PATH112}`, GET, option).json(),
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1ssvevw['get']['resBody']>(prefix, `${prefix3}${PATH112}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH112}`,
              },
              put: (option: { body: Methods_j6oyzg['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_j6oyzg['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option: { body: Methods_j6oyzg['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_j6oyzg['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          archive: {
            post: (option: { body: Methods_so7kot['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_so7kot['post']['resBody']>(prefix, PATH113, POST, option).json(),
            $post: (option: { body: Methods_so7kot['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_so7kot['post']['resBody']>(prefix, PATH113, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH113}`,
          },
          search: {
            get: (option?: { query?: Methods_1t4i5y9['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1t4i5y9['get']['resBody']>(prefix, PATH114, GET, option).json(),
            $get: (option?: { query?: Methods_1t4i5y9['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1t4i5y9['get']['resBody']>(prefix, PATH114, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1t4i5y9['get']['query'] } | undefined) =>
              `${prefix}${PATH114}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_3btn8w['get']['resBody']>(prefix, PATH111, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_3btn8w['get']['resBody']>(prefix, PATH111, GET, option).json().then(r => r.body),
          post: (option: { body: Methods_3btn8w['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_3btn8w['post']['resBody']>(prefix, PATH111, POST, option).json(),
          $post: (option: { body: Methods_3btn8w['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_3btn8w['post']['resBody']>(prefix, PATH111, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH111}`,
        },
        import_departments: {
          read: {
            post: (option: { body: Methods_u9ayjr['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_u9ayjr['post']['resBody']>(prefix, PATH116, POST, option, 'FormData').json(),
            $post: (option: { body: Methods_u9ayjr['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_u9ayjr['post']['resBody']>(prefix, PATH116, POST, option, 'FormData').json().then(r => r.body),
            $path: () => `${prefix}${PATH116}`,
          },
          post: (option: { body: Methods_1nvytny['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1nvytny['post']['resBody']>(prefix, PATH115, POST, option, 'FormData').json(),
          $post: (option: { body: Methods_1nvytny['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1nvytny['post']['resBody']>(prefix, PATH115, POST, option, 'FormData').json().then(r => r.body),
          $path: () => `${prefix}${PATH115}`,
        },
        seniors: {
          _id: (val3: number) => {
            const prefix3 = `${PATH117}/${val3}`;

            return {
              put: (option: { body: Methods_1p153f6['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1p153f6['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option: { body: Methods_1p153f6['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1p153f6['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          post: (option: { body: Methods_16ajw2e['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_16ajw2e['post']['resBody']>(prefix, PATH117, POST, option).json(),
          $post: (option: { body: Methods_16ajw2e['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_16ajw2e['post']['resBody']>(prefix, PATH117, POST, option).json().then(r => r.body),
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_16ajw2e['get']['resBody']>(prefix, PATH117, GET, option).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_16ajw2e['get']['resBody']>(prefix, PATH117, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH117}`,
        },
        users: {
          _id: (val3: number) => {
            const prefix3 = `${PATH118}/${val3}`;

            return {
              image: {
                post: (option: { body: Methods_r28q7b['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_r28q7b['post']['resBody']>(prefix, `${prefix3}${PATH119}`, POST, option, 'FormData').json(),
                $post: (option: { body: Methods_r28q7b['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_r28q7b['post']['resBody']>(prefix, `${prefix3}${PATH119}`, POST, option, 'FormData').json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH119}`,
              },
              post: (option: { body: Methods_1aqek6x['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1aqek6x['post']['resBody']>(prefix, prefix3, POST, option, 'FormData').json(),
              $post: (option: { body: Methods_1aqek6x['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1aqek6x['post']['resBody']>(prefix, prefix3, POST, option, 'FormData').json().then(r => r.body),
              put: (option: { body: Methods_1aqek6x['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1aqek6x['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option: { body: Methods_1aqek6x['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1aqek6x['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1aqek6x['get']['resBody']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1aqek6x['get']['resBody']>(prefix, prefix3, GET, option).json().then(r => r.body),
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1aqek6x['delete']['resBody']>(prefix, prefix3, DELETE, option).json(),
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1aqek6x['delete']['resBody']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          count: {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_13bf93j['get']['resBody']>(prefix, PATH120, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_13bf93j['get']['resBody']>(prefix, PATH120, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH120}`,
          },
          display_limits: {
            post: (option: { body: Methods_1ixckjl['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1ixckjl['post']['resBody']>(prefix, PATH121, POST, option, 'FormData').json(),
            $post: (option: { body: Methods_1ixckjl['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1ixckjl['post']['resBody']>(prefix, PATH121, POST, option, 'FormData').json().then(r => r.body),
            $path: () => `${prefix}${PATH121}`,
          },
          mail: {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1jhzvzb['get']['resBody']>(prefix, PATH122, GET, option).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1jhzvzb['get']['resBody']>(prefix, PATH122, GET, option).json().then(r => r.body),
            put: (option: { body: Methods_1jhzvzb['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1jhzvzb['put']['resBody']>(prefix, PATH122, PUT, option).json(),
            $put: (option: { body: Methods_1jhzvzb['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1jhzvzb['put']['resBody']>(prefix, PATH122, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH122}`,
          },
          get: (option?: { query?: Methods_1gql4f['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1gql4f['get']['resBody']>(prefix, PATH118, GET, option).json(),
          $get: (option?: { query?: Methods_1gql4f['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1gql4f['get']['resBody']>(prefix, PATH118, GET, option).json().then(r => r.body),
          post: (option: { body: Methods_1gql4f['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1gql4f['post']['resBody']>(prefix, PATH118, POST, option).json(),
          $post: (option: { body: Methods_1gql4f['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1gql4f['post']['resBody']>(prefix, PATH118, POST, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1gql4f['get']['query'] } | undefined) =>
            `${prefix}${PATH118}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      obf: {
        emotion_answers: {
          feedback: {
            _id: (val4: number) => {
              const prefix4 = `${PATH123}/${val4}`;

              return {
                post: (option: { body: Methods_1445it0['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1445it0['post']['resBody']>(prefix, prefix4, POST, option).json(),
                $post: (option: { body: Methods_1445it0['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1445it0['post']['resBody']>(prefix, prefix4, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
          },
        },
        members: {
          _code: (val3: string) => {
            const prefix3 = `${PATH124}/${val3}`;

            return {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_nua34e['get']['resBody']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_nua34e['get']['resBody']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          _id: (val3: number) => {
            const prefix3 = `${PATH124}/${val3}`;

            return {
              talent_examination: {
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_yjx02h['get']['resBody']>(prefix, `${prefix3}${PATH125}`, GET, option).json(),
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_yjx02h['get']['resBody']>(prefix, `${prefix3}${PATH125}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH125}`,
              },
              post: (option: { body: Methods_12idmcy['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_12idmcy['post']['resBody']>(prefix, prefix3, POST, option, 'FormData').json(),
              $post: (option: { body: Methods_12idmcy['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_12idmcy['post']['resBody']>(prefix, prefix3, POST, option, 'FormData').json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          anniversary: {
            get: (option?: { query?: Methods_7vjkrf['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_7vjkrf['get']['resBody']>(prefix, PATH126, GET, option).json(),
            $get: (option?: { query?: Methods_7vjkrf['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_7vjkrf['get']['resBody']>(prefix, PATH126, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_7vjkrf['get']['query'] } | undefined) =>
              `${prefix}${PATH126}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          message: {
            get: (option: { query: Methods_derogy['get']['query'], config?: T | undefined }) =>
              fetch<Methods_derogy['get']['resBody']>(prefix, PATH127, GET, option).json(),
            $get: (option: { query: Methods_derogy['get']['query'], config?: T | undefined }) =>
              fetch<Methods_derogy['get']['resBody']>(prefix, PATH127, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_derogy['get']['query'] } | undefined) =>
              `${prefix}${PATH127}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          search: {
            get: (option: { query: Methods_qxgdgj['get']['query'], config?: T | undefined }) =>
              fetch<Methods_qxgdgj['get']['resBody']>(prefix, PATH128, GET, option).json(),
            $get: (option: { query: Methods_qxgdgj['get']['query'], config?: T | undefined }) =>
              fetch<Methods_qxgdgj['get']['resBody']>(prefix, PATH128, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_qxgdgj['get']['query'] } | undefined) =>
              `${prefix}${PATH128}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          post: (option: { body: Methods_qj61yu['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_qj61yu['post']['resBody']>(prefix, PATH124, POST, option, 'FormData').json(),
          $post: (option: { body: Methods_qj61yu['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_qj61yu['post']['resBody']>(prefix, PATH124, POST, option, 'FormData').json().then(r => r.body),
          $path: () => `${prefix}${PATH124}`,
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
