import type { DefineMethods } from 'aspida'
import type { TalentExamination } from '~/types/talentExamination'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: TalentExamination
    }
  }
}>

const mockTaResult: Record<number, TalentExamination> = {
  1: {
    tips: [
      {
        category: 'テストタイプ',
        content:
          '自分から積極的に話すことが得意ではないため、正直な意見を伝えても大丈夫という安心感を与えるように心がけましょう。',
        keyword: '安心感を与える',
      },
      {
        category: '性格特徴',
        content:
          'また、主体的ではないためこちらからコミュニケーションをリードしていくと良いでしょう。',
        keyword: 'コミュニケーションをリードする',
      },
      {
        category: 'コミュニケーション力',
        content:
          '職場での状況や本人の考えを把握するために、積極的かつ具体的に質問することを推奨します。',
        keyword: '積極的・具体的に質問する',
      },
    ],
    retirement_risk: {
      tendency: '評価・評判',
      explanation:
        '・他者から自分がどのように評価されているかが気になり、ストレスを感じやすく、自身の業務や能力について自信をもてない傾向があります。\n\n・相手の評価を気にしすぎる余り同調的な回答をしやすいため、YESを取るような質問をするよりも、自分から話させるようにすることが必要です。\n\n・日々の業務においては、強みについての承認や称賛のFB機会を意図的に増やし、弱みや失敗した際の指導は、個別で実施しましょう。',
    },
    has_pdf: true,
  },
  2: {
    tips: [
      {
        category: 'テストタイプ',
        content:
          '自分から積極的に話す傾向があるため、まずは本人に話してもらいましょう。',
        keyword: 'まずは話してもらう',
      },
      {
        category: '性格特徴',
        content:
          'また、周囲への配慮が欠けてしまうことがあるため、冷静に周りの状況を把握するように促しましょう。',
        keyword: '周囲の状況を把握するよう促す',
      },
      {
        category: 'コミュニケーション力',
        content:
          '職場での状況や本人の考えを把握するために、単刀直入に質問を行うことを推奨します。',
        keyword: '単刀直入に質問を行う',
      },
    ],
    retirement_risk: undefined,
    has_pdf: true,
  },
}

export default mockMethods<Methods>({
  get: ({ path }) => {
    const codeString = path.split('/')[4]
    const code = codeString ? parseInt(codeString) : NaN
    const result = mockTaResult[code] || null
    return {
      status: 200,
      resBody: {
        result,
      },
    }
  },
})
